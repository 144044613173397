<template>
    <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="color" id="Icon" d="M12.3448 0.804598C12.0265 0.501427 11.5 0.727069 11.5 1.16667V4.3C11.5 4.6866 11.1866 5 10.8 5H0.7C0.313401 5 0 5.3134 0 5.7V14.3C0 14.6866 0.313401 15 0.7 15H10.8C11.1866 15 11.5 15.3134 11.5 15.7V18.8333C11.5 19.2729 12.0265 19.4986 12.3448 19.1954L21.6198 10.3621C21.8267 10.165 21.8267 9.83498 21.6198 9.63793L12.3448 0.804598Z" :fill="color"/>
</svg>
</template>

<script>
export default {
  name: 'Bold1',
  props: {
    color: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="css" scoped>
</style>