export default {
  FormatType: {
    popup: 0,
    bubble: 1,
    embed: 2,
    carousel: 3,
  },

  DeviceType: {
    mobile: 0,
    desktop: 1,
  },

  CtaType: {
    link: 0,
    copy: 1,
    input: 2,
    product: 3
  },

  Position: {
    left: 0,
    right: 1,
    center: 2
  },

  ctaActions: {
    addtoCart: 'addtoCart',
    buyNow: 'buyNow',
  },
}