<template>
  <small-modal
    :title="modalTitle"
    class="select-widget-modal"
    :fit-content="true"
    @close="close"
  >
    <div class="select-widget-modal-container">
      <h3 class="subtitle">
        {{ modalSubtitle }}
      </h3>
      <v-select
        v-model="selectedWidget"
        class="vidjet-single-select cta-dropdown"
        :placeholder="placeholder"
        :searchable="false"
        :clearable="false"
        label="name"
        :options="possibleWidgets"
        :reduce="(option) => option._id"
      >
      </v-select>
      <banner-modal
        class="banner"
        banner-type="info"
        :title="$t('deleteCampaign.information')"
        :text="$t('create.step2.addVideoToWidgetModal.banner')"
      >
      </banner-modal>
      <div class="buttons">
        <input
          class="button-primary --grey"
          type="button"
          :value="$t('deleteCampaign.cancel')"
          @click.prevent="close"
        />
        <div
          v-if="videosLoading"
          class="button-primary submit-button videos-loading"
        >
          <p>{{ $t("create.step2.addVideoToWidgetModal.loading") }}</p>
          <div class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>

        <input
          v-else
          class="button-primary submit-button"
          type="submit"
          :value="$t('create.step2.addVideoToWidgetModal.confirm')"
          @click.prevent="selectWidget"
        />
      </div>
    </div>
  </small-modal>
</template>

<script>
import SmallModal from "@/components/shared/SmallModal";
import BannerModal from "@/components/shared/BannerModal.vue";

export default {
  components: {
    SmallModal,
    BannerModal,
  },
  props: {
    possibleWidgets: {
      type: Array,
      required: true,
    },
    isAddWidget: {
      type: Boolean,
      default: false,
    },
    selectedVideos: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedWidget: null,
      selectedWidgetIndex: null,
      selectedWidgetId: null,
      videosLoading: false,
    };
  },
  computed: {
    placeholder() {
      return this.$t("create.step2.addVideoToWidgetModal.placeholder");
    },
    possibleWidgetsNames() {
      return this.possibleWidgets.map((widget) => widget.campaignName);
    },
    modalTitle() {
      let add = this.$t("create.step2.addVideoToWidgetModal.add");
      let plural = this.$t("create.step2.addVideoToWidgetModal.pluralTitle");
      let singular = this.$t(
        "create.step2.addVideoToWidgetModal.singularTitle"
      );
      if (this.selectedVideos.length > 1) {
        return `${add} ${this.selectedVideos.length} ${plural}`;
      } else {
        return `${add} ${this.selectedVideos.length} ${singular}`;
      }
    },
    modalSubtitle() {
      if (this.selectedVideos.length > 1) {
        return this.$t("create.step2.addVideoToWidgetModal.pluralSubtitle");
      } else
        return this.$t("create.step2.addVideoToWidgetModal.singularSubtitle");
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async selectWidget() {
      this.videosLoading = true;
      await this.$store.dispatch("campaign/addVideosToWidget", {
        campaignId: this.selectedWidget,
        videosIds: this.selectedVideos,
      });
      this.videosLoading = false;
      this.showAddVideosToWidgetModal = false;
      this.$emit("close");
      this.$notify({
        title: this.$t("shared.toastMessage.success"),
        text: this.$t("create.step2.addVideoToWidgetModal.toastMessage"),
        type: "success",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.select-widget-modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.subtitle {
  margin-bottom: 16px;
}

.cta-dropdown {
  width: 75%;
  margin-bottom: 24px;
}
.banner {
  margin-bottom: 36px;
  width: 90%;
}

.buttons {
  display: flex;
  justify-content: space-between;
  width: 90%;
  input {
    width: 170px;
    height: 40px;
  }
}

.videos-loading {
  display: flex;
  justify-content: end;
  align-items: center;
  box-sizing: content-box;
  gap: 12px;
  white-space: nowrap;
}

.lds-ring {
  color: $dark-purple;
}
.lds-ring,
.lds-ring div {
  box-sizing: border-box;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  border: 3px solid currentColor;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: currentColor transparent transparent transparent;
  right: 0;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
