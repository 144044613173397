<template>
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Icon">
    <rect class="color" id="Rectangle 779" y="-3.05176e-05" width="38" height="38" rx="2" :fill="secondaryColor" :style="{opacity: opacity}"/>
    <path id="vector (Stroke)" fill-rule="evenodd" clip-rule="evenodd" :fill="color" d="M26.5851 18.0209C27.1383 18.5617 27.1383 19.4383 26.5851 19.9791L21.8628 24.5945C21.3096 25.1352 20.4126 25.1352 19.8594 24.5945C19.3061 24.0537 19.3061 23.177 19.8594 22.6363L22.1632 20.3846L11.4167 20.3846C10.6343 20.3846 10 19.7647 10 19C10 18.2353 10.6343 17.6154 11.4167 17.6154L22.1632 17.6154L19.8594 15.3637C19.3061 14.823 19.3061 13.9463 19.8594 13.4055C20.4126 12.8648 21.3096 12.8648 21.8628 13.4055L26.5851 18.0209Z"/>
    </g>
    </svg>
</template>

<script>
export default {
  name: 'Line3',
  props: {
    color: {
      type: String,
      required: true,
    },
    secondaryColor: {
      type: String,
      required: true,
    },
    opacity: {
      type: Number,
      required: true,
    },
  },
}
</script>

<style lang="css" scoped>
</style>