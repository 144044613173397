<template>
  <div
    class="product-pages-wrapper"
    :class="{ 'fixed-product-pages': showCreateProduct }"
  >
    <div v-if="!isLoading" class="main-content-wrapper">
      <div class="header">
        <h1 class="title">{{ $t("shared.tooltip.products") }}</h1>
      </div>
      <div class="sub-header">
        <AutocompletedSearch
          class="products-search"
          :items="products"
          :type="productsString"
          @search="onAutocompletedOptionSelected"
        />
        <tippy arrow="true" placement="top" a11y="false">
          <template #trigger>
            <div class="products-with-videos-bar">
              <p>
                {{ $t("productPages.productsWithVideos") }}
                <span>{{ videoPercentage }}%</span>
              </p>
              <div class="bar">
                <div
                  class="filled-bar"
                  :style="{ width: `${videoPercentage}%` }"
                ></div>
              </div>
              <component :is="currentEmote" class="emote" />
            </div>
          </template>
          <div class="emotes-tippy-content">
            <div class="tippy-text">
              <p>{{ $t("productPages.salesInformation") }}</p>
            </div>
            <div class="emotes-wrapper">
              <div class="emote">
                <CryingEmote />
                <p>0-19%</p>
              </div>
              <div class="emote">
                <SadEmote />
                <p>20-34%</p>
              </div>
              <div class="emote">
                <NeutralEmote />
                <p>35-49%</p>
              </div>
              <div class="emote">
                <HappyEmote />
                <p>50-69%</p>
              </div>
              <div class="emote">
                <PartyEmote />
                <p>70-100%</p>
              </div>
            </div>
          </div>
        </tippy>
        <button
          class="button-primary button-no-shadows position-right"
          @click="openVideoLibrary"
        >
          {{ $t("dashboard.heading.videoLibrary") }}
        </button>
      </div>
      <div class="product-list-header">
        <div class="list-element" @click="toggleSort('names')">
          <p class="list-title">
            {{ $t("productPages.listItems.products") }}
            {{ products.length }}
          </p>
          <div class="sorting-arrows">
            <SortArrow
              :class="{
                'selected-arrow':
                  selectedSorting === 'names' && selectedArrow === 1,
              }"
            />
            <SortArrow
              class="reverse-arrow"
              :class="{
                'selected-arrow':
                  selectedSorting === 'names' && selectedArrow === -1,
              }"
            />
          </div>
        </div>
        <div class="list-element" @click="toggleSort('created')">
          <p class="list-title">
            {{ $t("productPages.listItems.created") }}
          </p>
          <div class="sorting-arrows">
            <SortArrow
              :class="{
                'selected-arrow':
                  selectedSorting === 'created' && selectedArrow === 1,
              }"
            />
            <SortArrow
              class="reverse-arrow"
              :class="{
                'selected-arrow':
                  selectedSorting === 'created' && selectedArrow === -1,
              }"
            />
          </div>
        </div>
        <div class="list-element" @click="toggleSort('quantity')">
          <p class="list-title">
            {{ $t("productPages.sortingOptions.quantity") }}
          </p>
          <div class="sorting-arrows">
            <SortArrow
              :class="{
                'selected-arrow':
                  selectedSorting === 'quantity' && selectedArrow === 1,
              }"
            />
            <SortArrow
              class="reverse-arrow"
              :class="{
                'selected-arrow':
                  selectedSorting === 'quantity' && selectedArrow === -1,
              }"
            />
          </div>
        </div>
        <div class="list-element" @click="toggleSort('status')">
          <p class="list-title">
            {{ $t("productPages.listItems.status") }}
          </p>
          <div class="sorting-arrows">
            <SortArrow
              :class="{
                'selected-arrow':
                  selectedSorting === 'status' && selectedArrow === 1,
              }"
            />
            <SortArrow
              class="reverse-arrow"
              :class="{
                'selected-arrow':
                  selectedSorting === 'status' && selectedArrow === -1,
              }"
            />
          </div>
        </div>
        <div class="list-element" @click="toggleSort('lastImport')">
          <p class="list-title">
            {{ $t("productPages.listItems.lastImport") }}
          </p>
          <div class="sorting-arrows">
            <SortArrow />
            <SortArrow class="reverse-arrow" />
          </div>
        </div>
        <div class="list-element" @click="toggleSort('widget')">
          <p class="list-title">
            {{ $t("productPages.listItems.widget") }}
          </p>
          <div class="sorting-arrows">
            <SortArrow
              :class="{
                'selected-arrow':
                  selectedSorting === 'widget' && selectedArrow === 1,
              }"
            />
            <SortArrow
              class="reverse-arrow"
              :class="{
                'selected-arrow':
                  selectedSorting === 'widget' && selectedArrow === -1,
              }"
            />
          </div>
        </div>
      </div>
      <div
        class="product-cards-container"
        :class="{ 'larger-margin': !showBottomButtons }"
      >
        <div v-if="!hasProducts" class="no-products">
          <div class="no-products-image">
            <img src="@/assets/sitting-woman.png" alt="sitting-woman" />
          </div>
          <div class="no-products-text">
            <p>{{ $t("productPages.noProductsInstructions.1") }}</p>
            <div class="second-sentence">
              <p>
                {{ $t("productPages.noProductsInstructions.2") }}
              </p>
              <p class="bold">
                {{ $t("productPages.noProductsInstructions.3") }}
              </p>
              <p>
                {{ $t("productPages.noProductsInstructions.4") }}
              </p>
              <p class="bold">
                {{ $t("productPages.noProductsInstructions.5") }}
              </p>
              <p>{{ $t("productPages.noProductsInstructions.6") }}</p>
            </div>
          </div>
        </div>
        <div
          v-for="{
            creationDate,
            imageUrl,
            name,
            variants,
            _id,
            videosAttached,
            formatsAttached,
            productId,
            videoLastImportDate,
          } in products"
          v-else
          :key="_id"
          :ref="_id"
          class="product-cards"
        >
          <div class="product-card">
            <div
              v-tippy="{ placement: 'top', arrow: true }"
              class="image-name"
              :content="name"
            >
              <img v-lazy="imageUrl" class="product-img" alt="" />
              <div class="name-info">
                <h2 class="product-name">{{ truncateName(name) }}</h2>
                <div>
                  <p v-if="isShopify" class="product-info">
                    {{ firstVariantPrice(variants) }}
                  </p>
                  <p v-else class="product-info">
                    {{ firstVariantPrice(variants) }}
                  </p>
                </div>
              </div>
            </div>
            <p class="product-creation-date">
              {{ formattedDate(creationDate) }}
            </p>
            <p class="product-creation-date">
              {{ calculateTotalQty(variants) }}
            </p>
            <div class="status">
              <p
                class="number-of-videos"
                :class="{
                  'there-are-videos': videosAttached && videosAttached.length,
                }"
              >
                <span v-if="videosAttached">{{ videosAttached.length }}</span>
                <span v-else>0</span>
                {{ $t("account.usage.videos") }}
              </p>
              <div
                @click="
                  addOrManageVideos(formatsAttached, videosAttached, productId)
                "
              >
                <p
                  v-if="videosAttached && videosAttached.length > 0"
                  class="add-manage"
                >
                  {{ $t("productPages.manageVideos") }}
                </p>
                <p
                  v-else
                  class="add-manage"
                  :class="{ 'disabled-button': !formatsAttached }"
                >
                  {{ $t("productPages.addVideos") }}
                </p>
              </div>
            </div>
            <p class="last-import">
              {{ safeFormattedDate(videoLastImportDate) }}
            </p>
            <div class="widgets">
              <div
                v-if="formatsAttached && formatsAttached.length > 0"
                class="widgets-images"
              >
                <template v-for="format in formatsAttached">
                  <tippy
                    :key="format.campaignId"
                    arrow="true"
                    placement="top"
                    a11y="false"
                    interactive="true"
                    max-width="500px"
                  >
                    <template #trigger>
                      <div class="widget-icon-wrapper">
                        <component
                          :is="getWidgetComponent(format.formatName)"
                          class="widget-icon"
                        />
                        <WarningIcon
                          v-if="format.notEmbedded"
                          class="warning-icon"
                        />
                      </div>
                    </template>
                    <div>
                      <div v-if="format.notEmbedded" class="tippy-content">
                        <p>
                          {{ $t("productPages.noViews") }}
                        </p>
                        <div
                          class="see-embed-code"
                          @click="openEmbedCodeModal(format.campaignId)"
                        >
                          {{ $t("productPages.seeEmbed") }}
                        </div>
                        <button class="button-primary">
                          {{ $t("productPages.check") }}
                        </button>
                      </div>
                      <div v-else class="tippy-content standard-tippy">
                        <div class="tippy-content-section tippy-top-block">
                          <p class="format-type">
                            {{ getWidgetFormat(format.formatName) }}
                          </p>
                          |
                          <p class="tooltip-campaign-name">
                            "{{ format.campaignName }}"
                          </p>
                        </div>
                        <div class="tippy-content-section tippy-bottom-block">
                          <div
                            class="see-embed-code"
                            @click="openWidgetFormat(format.campaignId)"
                          >
                            <PlayIcon class="tooltip-icons" />
                            {{ $t("productPages.widgetSettings") }}
                          </div>
                          <div
                            class="see-embed-code"
                            @click="openEmbedCodeModal(format.campaignId)"
                          >
                            <CodeIcon class="tooltip-icons" />
                            {{ $t("productPages.seeEmbed") }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </tippy>
                </template>
              </div>
              <div
                v-if="
                  !formatsAttached ||
                  formatsAttached.length < bulkCampaignCount.length
                "
                class="no-widget"
                @click="addWidget(productId, formatsAttached, videosAttached)"
              >
                <p>+</p>
              </div>
            </div>
            <button class="option-button" @click="openOptionsModal(_id)">
              <img
                src="../assets/campaign-options-button.png"
                alt="options-button"
              />
            </button>
          </div>
          <ProductOptionsModal
            v-if="showOptionsModal && selectedProductId === _id"
            :product="selectedProductObject"
            @close-options-modal="closeOptionsModal"
            @edit-product="editProduct"
            @open-delete-modal="openDeleteModal"
          />
        </div>
      </div>
      <div v-if="showBottomButtons" class="bottom-buttons">
        <button
          class="main-button first-button"
          @click="openCreateProductModal"
        >
          <CreateIcon />{{ $t("productPages.topButtons.create") }}
        </button>
        <button class="main-button second-button">
          <UploadIcon />{{ $t("productPages.topButtons.import") }}
        </button>
      </div>
      <transition name="modal-slide">
        <CreateProductSlidingModal
          v-if="showCreateProduct"
          :edit-mode="editMode"
          :edit-product-data="editProductData"
          @close-elements-modal="closeCreateProduct"
        />
      </transition>
      <DeleteProductModal
        v-if="showDeleteModal"
        :product-to-delete="productToDelete"
        @close="closeDeleteModal"
        @delete-product="deleteProduct"
      />
    </div>
    <div v-else>
      <SpinnerLoader />
    </div>
    <transition name="modal-slide">
      <VideoLibrarySlidingModal
        v-if="showVideoLibrary"
        :video-library-creation="videoLibraryCreation"
        @close-video-library="closeVideoLibrary"
      />
    </transition>
    <EmbedCodeModal
      v-if="showEmbedCodeModal"
      :campaign="selectedWidget"
      @close="closeEmbedCodeModal"
    />
    <transition name="modal-slide">
      <elements-sliding-modal
        v-if="showStep3Modal"
        :current-feed-edit-index="currentFeedEditIndex"
        :selected-current-feed-edit="selectedCurrentFeedEdit"
        :is-edit-bulk-format="false"
        :is-product-pages="true"
        :campaign-id="campaignIdForStep3"
        :selected-product-id="selectedProductId"
        :comes-from-step-one="comesFromStepOne"
        @close-elements-modal="closeStep3Modal"
      >
      </elements-sliding-modal>
    </transition>
    <transition name="modal-slide">
      <elements-sliding-modal
        v-if="showWidgetFormalModal"
        :current-feed-edit-index="0"
        :is-edit-bulk-format="true"
        :is-bulk="true"
        :campaign-id="campaignIdForStep3"
        @close-elements-modal="closeWidgetFormalModal"
      >
      </elements-sliding-modal>
    </transition>
    <SelectWidgetModal
      v-if="openAddVideosToWidgetModal"
      :possible-widgets="possibleWidgets"
      :is-add-widget="isAddWidget"
      :selected-product-id="selectedProductId"
      @close="closeAddVideosToWidgetModal"
      @widget-selected="addVideosToWidget"
    />
    <SpinnerLoader v-if="isFetching" />
  </div>
</template>

<script>
import SortArrow from "@/assets/svg/sort-arrow.svg?inline";
import UploadIcon from "@/assets/svg/upload-icon.svg?inline";
import CreateIcon from "@/assets/svg/create-icon.svg?inline";
import CryingEmote from "@/assets/svg/crying-emote.svg?inline";
import SadEmote from "@/assets/svg/sad-emote.svg?inline";
import NeutralEmote from "@/assets/svg/neutral-emote.svg?inline";
import HappyEmote from "@/assets/svg/happy-emote.svg?inline";
import PartyEmote from "@/assets/svg/party-emote.svg?inline";
import BubbleWidget from "@/assets/svg/bubble-widget.svg?inline";
import CarouselWidget from "@/assets/svg/carousel-widget.svg?inline";
import StoriesWidget from "@/assets/svg/stories-widget.svg?inline";
import PopupWidget from "@/assets/svg/popup-widget.svg?inline";
import EmbedWidget from "@/assets/svg/embed-widget.svg?inline";
import WarningIcon from "@/assets/svg/warning-icon.svg?inline";
import PlayIcon from "@/assets/svg/small-play-icon.svg?inline";
import CodeIcon from "@/assets/svg/embed-code-icon.svg?inline";

import AutocompletedSearch from "./shared/AutocompletedSearch.vue";
import ProductOptionsModal from "@/components/ProductOptionsModal.vue";
import SpinnerLoader from "./shared/SpinnerLoader.vue";
import CreateProductSlidingModal from "@/components/CreateProductSlidingModal.vue";
import DeleteProductModal from "@/components/DeleteProductModal.vue";
import VideoLibrarySlidingModal from "@/components/shared/VideoLibrarySlidingModal.vue";
import EmbedCodeModal from "@/components/campaign-manager/EmbedCodeModal.vue";
import ElementsSlidingModal from "@/components/create-campaign-steps/ElementsSlidingModal.vue";
import SelectWidgetModal from "@/components/SelectWidgetModal.vue";

import { mapGetters, mapState } from "vuex";

export default {
  components: {
    AutocompletedSearch,
    SortArrow,
    UploadIcon,
    CreateIcon,
    ProductOptionsModal,
    SpinnerLoader,
    CreateProductSlidingModal,
    DeleteProductModal,
    CryingEmote,
    SadEmote,
    NeutralEmote,
    HappyEmote,
    PartyEmote,
    VideoLibrarySlidingModal,
    BubbleWidget,
    CarouselWidget,
    StoriesWidget,
    EmbedCodeModal,
    ElementsSlidingModal,
    SelectWidgetModal,
    WarningIcon,
    PlayIcon,
    CodeIcon,
    PopupWidget,
    EmbedWidget,
  },
  props: {
    campaignId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      selectedProductId: null,
      selectedSorting: "names",
      selectedArrow: 1,
      showOptionsModal: false,
      selectedProductObject: null,
      isLoading: false,
      showCreateProduct: false,
      editMode: false,
      editProductData: null,
      showDeleteModal: false,
      productToDelete: null,
      showVideoLibrary: false,
      showEmbedCodeModal: false,
      campaigns: [],
      selectedWidget: null,
      showStep3Modal: false,
      possibleWidgets: [],
      openAddVideosToWidgetModal: false,
      campaignIdForStep3: null,
      isAddWidget: false,
      selectedCurrentFeedEdit: null,
      videoLibraryCreation: false,
      currentFeedEditIndex: null,
      isFetching: false,
      showWidgetFormalModal: false,
    };
  },
  computed: {
    ...mapState(["site"]),
    ...mapState({
      products: (state) => state.product.products,
    }),
    ...mapGetters({
      siteId: "account/getSiteId",
      currency: "site/getRawCurrency",
      getCurrency: "site/getCurrency",
      singleCampaign: "campaign/getSingle",
    }),
    isShopify() {
      return this.site.site.integration === "shopify";
    },
    hasProducts() {
      return this.products.length > 0;
    },
    productsString() {
      return this.$t("productPages.products");
    },
    videoPercentage() {
      if (this.products.length === 0) {
        return 0;
      }
      const productsWithVideos = this.products.filter(
        (product) => product.videosAttached
      );
      const percentage =
        (productsWithVideos.length / this.products.length) * 100;

      return Math.round(percentage);
    },
    currentEmote() {
      const percentage = this.videoPercentage;
      if (percentage <= 19) {
        return "CryingEmote";
      } else if (percentage <= 34) {
        return "SadEmote";
      } else if (percentage <= 49) {
        return "NeutralEmote";
      } else if (percentage <= 69) {
        return "HappyEmote";
      } else {
        return "PartyEmote";
      }
    },
    showBottomButtons() {
      if (
        !this.isShopify &&
        !(
          (this.site.site.integration === "woocommerce" &&
            this.products.length >= 3) ||
          (this.site.site.integration === "prestashop" &&
            this.products.length >= 3)
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    comesFromStepOne() {
      if (
        this.$route.query.campaignId &&
        this.$route.query.action !== "videoLibraryCreation"
      ) {
        return true;
      } else return false;
    },
    bulkCampaignCount() {
      return this.campaigns.filter((campaign) => Array.isArray(campaign.bulk));
    },
  },
  async created() {
    this.isLoading = true;

    await this.$store.dispatch("product/getProducts", {
      siteId: this.siteId,
      isProductListView: true,
    });
    await this.$store.dispatch("site/getSite", {
      siteId: this.siteId,
    });
    await this.$store.dispatch("site/getIntegrations", {
      siteId: this.siteId,
    });
    this.campaigns = await this.$store.dispatch("campaign/getCampaigns", {
      siteId: this.$store.state.account.user.siteId,
    });
    this.sortProducts();
    if (this.$route.query.action === "videoLibraryCreation") {
      this.videoLibraryCreation = true;
      this.showVideoLibrary = true;
    } else if (this.comesFromStepOne) {
      const productId = this.$route.query.productId;
      this.addVideosToWidget(this.campaignId, productId);
    }
    this.isLoading = false;
  },
  methods: {
    calculateTotalQty(variants) {
      if (variants) {
        let totalQty = variants.reduce(
          (total, variant) => total + variant.qty,
          0
        );
        let hasUnlimited = variants.some((variant) => variant.sellWithoutStock);

        if (hasUnlimited) {
          return this.$t("create.bulk.pagesModal.stock.unlimited");
        } else if (totalQty === 0) {
          return this.$t("create.bulk.pagesModal.stock.outStock");
        } else if (!variants[0].qty) {
          return "No info";
        } else {
          return totalQty + " " + this.$t("create.bulk.pagesModal.stock.stock");
        }
      }

      return this.$t("create.bulk.pagesModal.stock.outStock");
    },
    firstVariantPrice(variants) {
      if (variants && variants.length > 0) {
        const firstVariant = variants[0];
        const price = firstVariant ? firstVariant.price : 0;

        if (this.getCurrency === "$") {
          return `$${price}`;
        } else if (this.getCurrency === "€") {
          return `${price}€`;
        } else {
          return `${price}${this.getCurrency}`;
        }
      } else {
        return "";
      }
    },
    onAutocompletedOptionSelected(productItem) {
      const selectedProduct = this.products.find(
        (product) => product._id === productItem._id
      );
      if (selectedProduct) {
        this.scrollToProduct(selectedProduct);
      }
    },
    scrollToProduct(product) {
      this.$nextTick(() => {
        const productRef = this.$refs[product._id];
        if (productRef && productRef.length > 0) {
          const firstProductRef = productRef[0];
          firstProductRef.scrollIntoView({ behavior: "smooth" });
        }
      });
      this.selectedProductId = product._id;
      setTimeout(() => {
        this.selectedProductId = "";
      }, 5000);
    },
    toggleSort(sortType) {
      if (this.selectedSorting === sortType) {
        this.selectedArrow = this.selectedArrow === 1 ? -1 : 1;
      } else {
        this.selectedSorting = sortType;
        this.selectedArrow = 1;
      }
      this.sortProducts();
    },
    sortProducts() {
      const productsList = [...this.products].slice().sort((a, b) => {
        let comparison = 0;

        switch (this.selectedSorting) {
          case "names":
            comparison = a.name.localeCompare(b.name) * this.selectedArrow;
            break;
          case "created":
            comparison =
              (new Date(a.creationDate).getTime() -
                new Date(b.creationDate).getTime()) *
              this.selectedArrow;
            break;
          case "status":
            let videosA = a.videosAttached ? a.videosAttached.length : 0;
            let videosB = b.videosAttached ? b.videosAttached.length : 0;
            comparison = (videosA - videosB) * this.selectedArrow;
            break;
          case "lastImport":
            if (!a.videoLastImportDate) return this.selectedArrow;
            if (!b.videoLastImportDate) return -this.selectedArrow;
            comparison =
              (new Date(a.videoLastImportDate).getTime() -
                new Date(b.videoLastImportDate).getTime()) *
              this.selectedArrow;
            break;
          case "widget":
            let widgetsA = a.formatsAttached ? a.formatsAttached.length : 0;
            let widgetsB = b.formatsAttached ? b.formatsAttached.length : 0;
            comparison = (widgetsA - widgetsB) * this.selectedArrow;
            break;
          case "quantity":
            let qtyA = this.calculateSortableQty(a.variants);
            let qtyB = this.calculateSortableQty(b.variants);
            comparison = (qtyA - qtyB) * this.selectedArrow;
            break;
          default:
            break;
        }
        return comparison;
      });
      this.$store.commit("product/setProducts", productsList);
    },
    openOptionsModal(productId) {
      this.showOptionsModal = true;
      this.selectedProductId = productId;
      this.selectedProductObject = this.products.find(
        (product) => product._id === productId
      );
    },
    closeOptionsModal() {
      this.showOptionsModal = false;
      this.selectedProductId = null;
      this.selectedProductObject = null;
    },
    openCreateProductModal() {
      this.showCreateProduct = true;
    },
    closeCreateProduct() {
      this.showCreateProduct = false;
    },
    editProduct(payload) {
      this.editProductData = this.products.find(
        (product) => product._id === payload._id
      );
      this.editMode = true;
      this.showOptionsModal = false;
      this.openCreateProductModal();
    },
    openDeleteModal(product) {
      this.productToDelete = product;
      this.showDeleteModal = true;
      this.showOptionsModal = false;
    },
    closeDeleteModal() {
      this.showDeleteModal = false;
    },
    async deleteProduct(productId) {
      try {
        if (productId) {
          await this.$store.dispatch("product/deleteProduct", { productId });
          this.$notify({
            title: this.$t("shared.toastMessage.success"),
            text: this.$t("productPages.deleteModal.deleted"),
            type: "success",
          });
        }
      } catch (error) {
        console.error("Error deleting product:", error);
      }
    },

    openVideoLibrary() {
      this.showVideoLibrary = true;
    },
    closeVideoLibrary() {
      this.showVideoLibrary = false;
    },
    formattedDate(date) {
      const options = { year: "numeric", month: "short", day: "numeric" };
      return new Date(date).toLocaleDateString("en-US", options);
    },
    safeFormattedDate(date) {
      const formatted = this.formattedDate(date);
      // Check if the formatted date is valid
      if (formatted === "Invalid Date") {
        return "N/A";
      }
      return formatted;
    },
    truncateName(name) {
      return name.length > 23 ? name.substring(0, 20) + "..." : name;
    },
    numberOfVideosInProduct(product) {
      if (product.videosAttached && Array.isArray(product.videosAttached)) {
        return product.videosAttached.length;
      } else {
        return 0;
      }
    },
    getWidgetComponent(formatName) {
      switch (formatName) {
        case "carousel":
          return "CarouselWidget";
        case "story":
          return "StoriesWidget";
        case "bubble":
          return "BubbleWidget";
        case "popup":
          return "PopupWidget";
        case "embed":
          return "EmbedWidget";
        default:
          return "";
      }
    },
    addWidget(productId, formatsAttached, videosAttached) {
      if (this.bulkCampaignCount.length === 0) {
        this.isFetching = true;
        this.$router.push({
          name: "Step1FromProductPages",
          query: {
            action: "ProductPageWidgetCreation",
            productId: productId,
          },
        });
        this.isFetching = false;
      } else {
        const attachedCampaignIds = (formatsAttached || []).map(
          (format) => format.campaignId
        );

        const filteredBulkCampaignCount = this.bulkCampaignCount.filter(
          (campaign) => !attachedCampaignIds.includes(campaign._id)
        );
        this.isAddWidget = true;
        this.addOrManageVideos(
          filteredBulkCampaignCount,
          videosAttached,
          productId
        );
      }
    },
    getWidgetFormat(formatName) {
      switch (formatName) {
        case "carousel":
          return "Carousel";
        case "story":
          return "Stories";
        case "bubble":
          return "Bubble";
        case "popup":
          return "Popup";
        case "embed":
          return "Embed";
        default:
          return "";
      }
    },
    openEmbedCodeModal(campaignId) {
      const selectedCampaign = this.campaigns.find(
        (campaign) => campaign._id === campaignId
      );

      if (selectedCampaign) {
        this.selectedWidget = selectedCampaign;
        this.showEmbedCodeModal = true;
      }
    },
    closeEmbedCodeModal() {
      this.selectedWidget = null;
      this.showEmbedCodeModal = false;
    },
    async addOrManageVideos(formatsAttached, videosAttached, productId) {
      this.selectedProductId = productId;
      if (formatsAttached && formatsAttached.length === 1) {
        this.isFetching = true;
        if (this.isAddWidget) {
          this.addVideosToWidget(formatsAttached[0]._id, productId);
        } else {
          this.addVideosToWidget(formatsAttached[0].campaignId, productId);
        }
      } else if (formatsAttached && formatsAttached.length > 1) {
        this.openAddVideosToWidgetModal = true;
        this.possibleWidgets = formatsAttached;
      } else if (!formatsAttached && !videosAttached) {
        return;
      }
    },
    async addVideosToWidget(campaignId, productId) {
      this.selectedProductId = productId;
      this.openAddVideosToWidgetModal = false;
      this.campaignIdForStep3 = campaignId;

      //Here we should just get the feed data and not the full campaign object
      let campaign = await this.fetchCampaign(
        this.campaignIdForStep3,
        this.siteId
      );

      let matchingBulk = this.findMatchingBulk(campaign, productId);
      if (matchingBulk) {
        this.$store.commit("campaign/setCurrentFeedEdit", matchingBulk);
        this.selectedCurrentFeedEdit = matchingBulk;
      } else {
        const newFeed = this.createNewFeed(campaignId, productId);
        this.$store.commit("campaign/setCurrentFeedEdit", newFeed);
        this.selectedCurrentFeedEdit = newFeed;

        //Since we juste created a feed, the index will be equal to the previous length of the bulk array
        this.currentFeedEditIndex = campaign.bulk.length;
      }
      this.showStep3Modal = true;
    },
    closeStep3Modal() {
      this.showStep3Modal = false;
      this.isFetching = false;
      this.campaignIdForStep3 = null;
      this.isAddWidget = false;
      this.$store.commit("campaign/setCurrentFeedEdit", null);
    },
    closeAddVideosToWidgetModal() {
      this.openAddVideosToWidgetModal = false;
      this.isAddWidget = false;
    },
    async fetchCampaign(campaignId, siteId) {
      const { campaign } = await this.$store.dispatch("campaign/getSingle", {
        campaignId: campaignId,
        siteId: siteId,
      });
      return campaign;
    },
    findMatchingBulk(campaign, productId) {
      const matchingIndex = campaign.bulk.findIndex(
        (bulkObject) =>
          bulkObject.segmentation &&
          bulkObject.segmentation.productId &&
          bulkObject.segmentation.productId.includes(productId)
      );

      if (matchingIndex !== -1) {
        this.currentFeedEditIndex = matchingIndex;
        return campaign.bulk[matchingIndex];
      } else {
        return null;
      }
    },
    createNewFeed(campaignId, productId) {
      let matchingProduct = this.products.find(
        (product) => product.productId === productId
      );

      const newFeed = {
        feedName: matchingProduct.name,
        segmentation: {
          productId: [productId],
        },
        videos: [],
        creationDate: new Date().toISOString(),
      };

      this.$store.dispatch("campaign/createFeed", { campaignId, newFeed });
      return newFeed;
    },
    calculateSortableQty(variants) {
      if (!variants || variants.length === 0) {
        return 0;
      }

      let hasUnlimited = variants.some((variant) => variant.sellWithoutStock);

      if (hasUnlimited) {
        return Infinity;
      }

      return variants.reduce((total, variant) => total + (variant.qty || 0), 0);
    },
    async openWidgetFormat(campaignId) {
      this.campaignIdForStep3 = campaignId;
      let campaign = await this.fetchCampaign(
        this.campaignIdForStep3,
        this.siteId
      );
      this.$store.commit("campaign/setFeedList", campaign.bulk);
      this.showWidgetFormalModal = true;
    },
    closeWidgetFormalModal() {
      this.showWidgetFormalModal = false;
    },
  },
};
</script>

<style lang="scss">
.widget-info-tippy .tippy-tooltip {
  max-width: 400px !important;
}
</style>

<style lang="scss" scoped>
.product-pages-wrapper {
  width: 100%;
  height: 100%;
}

.fixed-product-pages {
  position: fixed;
}
.main-content-wrapper {
  margin-right: 30px;
  margin-left: 100px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bottom-buttons {
  display: flex;
  margin-bottom: 36px;
}

.main-button {
  @include base-font;
  @include font-smaller;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 24px;
  background: white;
  border: 1px solid $medium-grey;
}

.first-button {
  border-radius: 3px 0px 0px 3px;
}

.second-button {
  border-radius: 0px 3px 3px 0px;
  border-left: none;
}

.sub-header {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;
  position: relative;
}

.sort-button {
  display: flex;
  gap: 8px;
  align-items: center;
  border: 1px solid $dark-grey;
  border-radius: 2px;
  padding: 4px 12px;
  height: 14px;
  &:hover {
    background: #f1f1f6;
    cursor: pointer;
    svg {
      path {
        fill: $dark-grey;
      }
    }
  }
}

.product-cards-container {
  margin-bottom: 16px;
}

.larger-margin {
  margin-bottom: 36px;
}

.product-list-header {
  display: grid;
  grid-template-columns: 2.5fr 1fr 1fr 1fr 1fr 2fr 0.5fr;
  color: #585858;
  background: #ececec;
  border: 1px solid #ececec;
  box-sizing: border-box;
  border-radius: 2px;
  align-items: center;
  text-align: start;
  @include font-small;
  height: 100%;
  padding: 14px;
}

.product-cards {
  position: relative;
}

.product-card {
  display: grid;
  grid-template-columns: 2.5fr 1fr 1fr 1fr 1fr 2fr 0.5fr;
  padding: 8px 14px;
  align-items: center;
  border-bottom: 1px solid $light-grey;
  &:hover {
    background-color: #c4c4c420;
  }
}

.image-name {
  display: flex;
  align-items: center;
  gap: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.product-img {
  width: 46px;
  height: 46px;
  border-radius: 2px;
  border: 1px solid $light-grey;
}

.name-info {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.product-name {
  @include font-small;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product-info {
  @include font-smaller;
  font-weight: 400;
  color: #7f7f7f;
}

.option-button {
  background: none;
  padding: 0;
  width: 54px;
  align-self: flex-end;
  display: flex;
  align-items: center;
  justify-self: flex-end;
  img {
    margin-top: 2px;
  }
}

.no-products {
  height: 300px;
  background-color: $light-pink;
  display: flex;
  align-items: center;
  gap: 40px;
  padding: 40px;
  margin-top: 16px;
}

.no-products-image {
  display: flex;
  justify-content: center;
}

.no-products-text {
  display: flex;
  flex-direction: column;
  gap: 40px;
  p {
    @include base-font;
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;
  }
}
.second-sentence {
  p {
    display: inline;
  }
  .bold {
    font-weight: 700;
  }
}

.no-products-instructions {
  width: calc(20% - 20px);
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: flex-start;
  .instructions-text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 108px;
    height: 116px;
    border: 2px solid $dark-grey;
    border-radius: 4px;
    padding: 16px 16px;
    box-sizing: border-box;
    p {
      @include font-normal;
      text-align: center;
      font-family: "Gloria Hallelujah";
    }
  }
}

.sort-button {
  position: relative;
  display: flex;
  gap: 8px;
  align-items: center;
  border: 1px solid $dark-grey;
  border-radius: 2px;
  padding: 4px 12px;
  height: 14px;
  &:hover {
    background: #f1f1f6;
    cursor: pointer;
    svg {
      path {
        fill: $dark-grey;
      }
    }
  }
}

.sort-arrows {
  display: flex;
  flex-direction: column;
  gap: 4px;
  transform: scale(50%);
  margin-right: 1px;
  path {
    fill: $dark-grey;
  }
}
.reverse-arrow {
  transform: translateX(-0.9px);
  path {
    transform: rotate(180deg);
    transform-origin: 50% 50%;
  }
}

.sort-by {
  @include font-smaller;
  color: $dark-grey;
  &:hover {
    color: $dark-grey;
  }
}

.selected-sort-button {
  background: $dark-purple;
  svg {
    path {
      fill: white;
    }
  }
  p {
    color: white;
  }
}
.number-sorting {
  position: absolute;
  top: 3px;
  left: 23px;
  font-size: 6px;
  line-height: 7px;
  text-align: center;
  align-items: center;
  width: 10px;
  height: 10px;
  font-weight: 500;
  background-color: $dark-purple;
  color: white;
  padding: 2px 1px 1px 1px;
  border-radius: 50%;
  box-sizing: border-box;
}
.products-with-videos-bar {
  height: 24px;
  border: 1px solid $light-grey;
  border-radius: 2px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 0 16px;
  gap: 8px;
  p {
    @include font-smaller;
    font-weight: 400;
  }
  span {
    font-weight: 700;
  }
}
.bar {
  position: relative;
  height: 6px;
  width: 125px;
  border-radius: 4px;
  background-color: #ececec;
}
.filled-bar {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px;
  height: 100%;
  background-color: $dark-purple;
  transition: width 0.5s ease;
}
.position-right {
  position: absolute;
  right: 0;
}
.list-element {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}
.list-title {
  @include font-small;
  font-weight: 600;
  white-space: nowrap;
}
.sorting-arrows {
  display: flex;
  flex-direction: column;
  gap: 6px;
  transform: scale(60%);
}

.product-creation-date {
  @include font-smaller;
  font-weight: 400;
}

.status {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.number-of-videos {
  @include font-smaller;
  font-weight: 400;
  background-color: #e2e2e2;
  width: fit-content;
  border-radius: 20px;
  padding: 2px 12px;
  span {
    font-weight: 600;
  }
}

.there-are-videos {
  background-color: #d4f8d3;
}

.add-manage {
  @include font-smaller;
  color: $dark-purple;
  text-decoration: underline;
  cursor: pointer;
}

.last-import {
  @include font-smaller;
  font-weight: 400;
}

.no-widget {
  width: 58.5px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fae8ffad;
  border: 2px dashed $dark-purple;
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 2px;
  p {
    font-weight: 600;
    color: $dark-purple;
    border: 2px solid $dark-purple;
    border-radius: 2px;
    width: 10px;
    height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
  }
  &:hover {
    background-color: #fae8ff;
    cursor: pointer;
  }
}

.widgets {
  display: flex;
  gap: 8px;
  align-items: center;
}
.widgets-images {
  display: flex;
  gap: 8px;
  align-items: center;
}

.widget-icon-wrapper {
  position: relative;
}

.warning-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.tippy-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 4px 8px;
  button {
    width: 50%;
    &:hover {
      background-color: $light-grey;
    }
  }
}

.tooltip-campaign-name {
  font-style: italic;
}

.see-embed-code {
  cursor: pointer;
  padding: 4px 8px;
  white-space: nowrap;
  background-color: white;
  border-radius: 2px;
  color: $dark-purple;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-weight: 500;
}

.selected-arrow {
  path {
    fill: $light-purple;
  }
}
.emotes-tippy-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 4px;
  padding: 8px 16px;
  p {
    text-align: start;
    font-weight: 500;
  }
}

.emotes-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.disabled-button {
  color: $light-grey;
  pointer-events: none;
}
.standard-tippy {
  align-items: flex-start;
  gap: 12px;
}

.tippy-content-section {
  display: flex;
  gap: 8px;
  justify-self: flex-start;
}

.tippy-top-block {
  padding: 2px 8px 0 0;
}

.tippy-bottom-block {
  padding-bottom: 6px;
  gap: 16px;
}

.format-type {
  font-weight: bold;
}

.tooltip-icons {
  path {
    fill: $dark-purple;
  }
}
</style>
