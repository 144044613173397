<template>
  <section class="step3-wrapper">
    <button v-if="!isBulk" class="close-icon" @click="toggleConfirmationModal">
      <CloseIcon />
    </button>
    <!-- make this an isolated component -->
    <section class="formatStep">
      <div
        v-if="!currentVideo && !playerSelected"
        class="step-navbar"
        :class="{ 'bulk-step-navbar': isBulk }"
      >
        <div v-if="campaignHasNoVideos" class="no-videos-section">
          <div class="no-products-images">
            <img
              src="@/assets/young-women-computer.png"
              alt="young-women-computer"
            />
            <img
              src="@/assets/add-videos-below.png"
              alt="add-videos-below"
              class="absolute-image"
            />
          </div>
          <div class="no-products-text">
            <p class="main-text">No videos added</p>
            <p class="secondary-text">Videos will appear here</p>
          </div>
        </div>
        <section
          v-else
          class="video-cards"
          :class="
            ({ 'slide-from-right': isBackButtonClicked },
            { 'bulk-video-cards': isBulk })
          "
        >
          <h1 v-if="!isBulk">{{ $t("create.step3.title") }}</h1>
          <div
            v-if="!isBulk"
            class="general-player-button"
            @click="playerSelected = true"
          >
            <div class="player-settings">
              <h3>{{ $t("create.step3.playerSettings") }}</h3>
              <p v-if="isDefaultSettings">
                {{ $t("create.step3.defaultSettings") }}
              </p>
              <p v-else>{{ $t("create.step3.customSettings") }}</p>
            </div>
            <img src="@/assets/arrow-next-simple.png" alt="arrow" />
          </div>

          <draggable
            v-model="campaign.videos"
            group="videos"
            @change="changeOrder($event)"
          >
            <video-card
              v-for="(video, index) in campaign.videos"
              :key="video._id"
              :class="{ 'bulk-video-card': isBulk }"
              :video="video"
              :videos="campaign.videos"
              :img="video.thumbnail"
              :is-portrait="video.height > video.width"
              :index="index"
              :cta="video.cta"
              :is-bulk="isBulk"
              :is-carousel="isCarousel"
              :is-full-screen="isFullScreen"
              :is-main-grouping="video.isMainGrouping"
              :thumbnail-grouping="video.thumbnailGrouping"
              @click="currentVideo = { video, index }"
              @delete="deleteVideo"
              @group-thumbnail="openGroupingThumbnailModal(video)"
              @disable-thumbnail="disableMainThumbnail(video)"
              @change-index="handleChangeIndex"
            ></video-card>
          </draggable>
        </section>
        <div class="settings-footer-wrapper">
          <edit-footer
            v-if="isEditMode && !isBulk"
            :prev-text="$t('shared.buttons.back')"
            :next-text="$t('shared.buttons.save')"
            @prev="editGoBack"
            @next="goNextStep"
          />
          <bulk-embed-confirm
            v-else-if="isBulk"
            :is-step3="true"
            :is-product-pages="isProductPages"
            @confirm="goNextStep"
            @cancel="$emit('close-elements-modal')"
          />
          <settings-footer v-else @prev="goPreviousStep" @next="goNextStep" />
        </div>
      </div>
      <!-- START SETTINGS -->
      <div
        v-else-if="playerSelected"
        class="settings"
        :class="{ 'bulk-settings': isBulk }"
      >
        <div v-if="!isBulk" class="player-title-wrapper">
          <button class="back-button" @click="deselectPlayer(false)">
            <ArrowBackIcon />
            <p>{{ $t("shared.buttons.back") }}</p>
          </button>
          <h2 class="step-title title-bolder">
            {{ $t("create.step3.playerTitle") }}
          </h2>
        </div>
        <CampaignFormatSettings
          v-if="campaignLoaded"
          class="slide-from-left"
          :class="{ 'bulk-slide-from-left': isBulk }"
          :is-bulk="isBulk"
          :campaign="campaign"
          :desktop="desktop"
          :mobile="mobile"
          :chosen-device="chosenDevice"
          :is-carousel="isCarousel"
          :is-embed="isEmbed"
          :campaign-allowed="campaignAllowedOn"
          :current-plan="currentPlan"
          @update-device="updateDevice"
          @format-object-change="changeFormatObject"
          @video-title-change="updateVideoTitle"
          @stickybar-object-change="changeStickyBarObject"
          @format-type-change="handleFormatTypeChange"
          @fill-object-if-empty="fillObjectIfEmpty"
          @change-cta-object="changeCtaObject($event, 0)"
          @change-image="changeThumbnail($event, 0)"
          @previous-step="goPreviousStep"
          @next-step="goNextStep"
          @edit-go-back="editGoBack"
          @border-color-changed="handleBorderColorChanged"
        ></CampaignFormatSettings>
        <bulk-embed-confirm
          v-if="isBulk"
          @confirm="goNextStep"
          @cancel="$emit('close-elements-modal')"
        />
        <div v-else class="done-button-wrapper">
          <div class="done-button" @click="deselectPlayer(true)">
            {{ $t("shared.buttons.done") }} 👌
          </div>
        </div>
      </div>
      <div
        v-else-if="currentVideo"
        class="cta-navbar"
        :class="{ 'bulk-cta-navbar': isBulk }"
      >
        <div class="title-wrapper" :class="{ 'bulk-title-wrapper': isBulk }">
          <button class="back-button" @click="deselectVideo(false, null)">
            <ArrowBackIcon />
            <p>{{ $t("shared.buttons.back") }}</p>
          </button>
          <h1 class="step-title title-bolder">
            {{ $t("create.step3.selectElement") }}
          </h1>
        </div>
        <div class="cta-wrapper">
          <cta-block
            class="slide-from-left"
            :class="{ 'bulk-slide-from-left': isBulk }"
            :campaign-cta-option="campaign.videos[currentVideo.index].cta"
            :chosen-device="chosenDevice"
            :show-cta-button="campaign.videos && campaign.videos.length > 1"
            :products="currentVideoCta.products"
            :is-full-screen="isFullScreen"
            :is-bulk="isBulk"
            :is-edit="isEditMode"
            :campaign="campaign"
            :video-index="currentVideo.index"
            :current-feed-edit-index="currentFeedEditIndex"
            @change-cta-object="changeCtaObject($event)"
            @open-add-to-cart="showAddToCartModal = true"
            @copy-cta-button-clicked="copyCtaForAllVideos($event)"
            @product-order-change="handleProductOrderChange"
            @remove-product="removeProduct"
          ></cta-block>
          <thumbnail-selector
            v-if="isCarousel || isEmbed"
            class="slide-from-left"
            :has-thumbnail="
              Boolean(campaign.videos[currentVideo.index].thumbnail)
            "
            :class="[{ 'bulk-slide-from-left': isBulk }]"
            @upload-image="changeThumbnail($event)"
          ></thumbnail-selector>
          <CarouselTextSettings
            v-if="isCarousel || isEmbed"
            :text-options="currentVideoTextOptions"
            :is-story="isStory"
            :is-bulk="isBulk"
            :current-index="currentVideo.index"
            :campaign-videos="campaign.videos"
            :current-feed-edit-index="currentFeedEditIndex"
            class="slide-from-left"
            :class="{ 'bulk-slide-from-left': isBulk }"
            @change-text-object="changeText"
          ></CarouselTextSettings>
        </div>
        <div
          class="done-button-wrapper"
          :class="{
            'bulk-done-button': isBulk,
            'product-pages-done-button': isProductPages,
          }"
        >
          <div
            class="done-button"
            @click="deselectVideo(true, campaign.videos[currentVideo.index])"
          >
            {{ $t("shared.buttons.done") }} 👌
          </div>
        </div>
      </div>

      <vidjet-preview
        v-if="
          (!(openVideoLibrary && selectedVideo) &&
            (currentVideo || videoToPreview.videos.length) &&
            campaignLoaded) ||
          campaignHasNoVideos
        "
        :is-embed="isEmbed"
        :class="{ 'bulk-preview': isBulk }"
        :is-carousel="isCarousel"
        :preview-campaign="videoToPreview"
        :chosen-device="!playerSelected ? getAllowedCampaign : chosenDevice"
        :embed-thumbnail="embedThumbnail"
        :desktop="desktop"
        :mobile="mobile"
        :cta-preview="currentVideoCta"
        :text-options-preview="currentVideoTextOptions"
        :campaign-type="campaign.campaignType"
        :hide-video="playerSelected && !campaignAllowedOn[chosenDevice]"
        :is-edit-mode="isEditMode"
        :is-desktop-toggled="isDesktopToggled"
        :progress-bar-step="2"
        :is-mobile-toggled="isMobileToggled"
        :all-videos="campaign.videos"
        :show-preview="showPreview"
        :is-product-pages="isProductPages"
        :is-bulk="isBulk"
        :campaign-has-no-videos="campaignHasNoVideos"
        @add-product="userDontNeedProducts()"
        @fullscreen-toggle="handleFullscreenToggle"
      />
      <vidjet-preview
        v-if="openVideoLibrary && selectedVideo"
        :is-step2="true"
        :is-embed="true"
        :is-video-library-preview="true"
        :preview-campaign="selectedVideo"
      ></vidjet-preview>
      <small-modal v-if="showSignupModal" @close="toggleSignupModal"
        ><signup-login
          :is-modal="true"
          @login-done="showSignupModal = false"
          @signup-done="showSignupModal = false"
      /></small-modal>
      <spinner-loader
        v-if="isLoading"
        :loading-text="$t('shared.loader.uploadingVideo')"
        :loading-text-additional="$t('shared.loader.uploadingVideo2')"
      ></spinner-loader>
      <spinner-loader
        v-if="showUploadProgress"
        :loading-text="$t('shared.loader.uploadingVideo')"
        :loading-text-additional="$t('shared.loader.uploadingVideo2')"
        :start-value="getUploadVideos.videosUploaded"
        :end-value="getUploadVideos.videosToUpload"
        @videos-uploaded="saveCampaignToDb()"
      />
      <AddToCartModal
        v-if="showAddToCartModal && currentVideo"
        :is-full-screen="isFullScreen"
        :selected-products-edit="
          currentVideoCta.products && currentVideoCta.products.length > 0
            ? currentVideoCta.products
            : false
        "
        @close-add-to-cart="showAddToCartModal = false"
        @submit-add-to-cart-products="submitAddToCartProducts"
      />
    </section>
    <small-modal
      v-if="showConfirmationModal"
      :fit-content="true"
      @close="toggleConfirmationModal"
    >
      <div class="confirmation-modal-content">
        <h1 class="confirmation-modal-title">
          {{ $t("create.step3.leaveModal.title") }}
        </h1>
        <h2 class="confirmation-modal-subtitle">
          {{ $t("create.step3.leaveModal.subtitle") }}
        </h2>
        <banner-modal
          banner-type="info"
          :title="$t('deleteCampaign.information')"
          :text="$t('create.step3.leaveModal.bannerInfo')"
        >
          <template #icon>
            <InfoIcon />
          </template>
        </banner-modal>
        <div class="confirmation-modal-buttons">
          <button
            class="button-primary cancel-button"
            @click="toggleConfirmationModal"
          >
            {{ $t("create.step3.leaveModal.cancel") }}
          </button>
          <button class="button-primary" @click="leaveCampaignCreator">
            {{ $t("create.step3.leaveModal.confirm") }}
          </button>
        </div>
      </div>
    </small-modal>
    <GroupingThumbnailModal
      v-if="showGroupingThumbnailModal"
      :video="videoToGroupInThumbnail"
      @group-thumbnail="setAsMainThumbnail"
      @close="showGroupingThumbnailModal = false"
    />
    <FloatingUploadingBar
      v-if="!isEditBulkFormat"
      @open-video-library="openVideoLibrary = true"
      @push-files="pushFiles"
    />
    <sliding-modal
      v-if="openVideoLibrary"
      class="video-library-modal"
      :slide-direction="slideDirection"
      @close="closeVideoLibrary"
    >
      <VideoLibrary
        @play-video-in-preview="selectVideoInPreview"
        @import-videos="importVideosFromLibrary"
        @close-library="closeVideoLibrary"
      />
    </sliding-modal>
    <EmbedCodeModal
      v-if="showEmbedCodeModal"
      :campaign="campaign"
      @close="closeEmbedCodeModal"
    />
  </section>
</template>
<script>
import { mapGetters, mapState } from "vuex";

import CampaignFormatSettings from "./step3/CampaignFormatSettings.vue";
import VidjetPreview from "../shared/Vidjet-preview.vue";
import SettingsFooter from "@/components/create-campaign-steps/SettingsFooter";
import SmallModal from "@/components/shared/SmallModal";
import EditFooter from "@/components/create-campaign-steps/EditFooter";
import SignupLogin from "@/components/shared/SignupLogin";
import SpinnerLoader from "../shared/SpinnerLoader.vue";
import enums from "../../enums";
import CtaBlock from "./step3/CtaBlock.vue";
import AddToCartModal from "./step3/AddToCartModal.vue";
import SlidingModal from "@/components/shared/SlidingModal.vue";
import VideoLibrary from "@/components/create-campaign-steps/VideoLibrary.vue";
import { generateVideoCover } from "@/utils/generate-video-cover";

import campaignCreationMixin from "./campaignCreationMixin";
import VideoCard from "./step3/VideoCard.vue";
import ArrowBackIcon from "@/assets/svg/arrow-back.svg?inline";
import Draggable from "vuedraggable";
import ThumbnailSelector from "./step3/ThumbnailSelector.vue";
import { uploadThumbnailForVideo } from "../../utils/uploadToAmplify";
import BannerModal from "@/components/shared/BannerModal.vue";
import InfoIcon from "@/assets/svg/banner-info-icon.svg?inline";
import CarouselTextSettings from "./step3/CarouselTextSettings.vue";
import CloseIcon from "@/assets/svg/settings/close.svg?inline";
import BulkEmbedConfirm from "./BulkEmbedConfirm.vue";
import GroupingThumbnailModal from "@/components/create-campaign-steps/step3/GroupingThumbnailModal.vue";
import FloatingUploadingBar from "@/components/FloatingUploadingBar.vue";
import EmbedCodeModal from "@/components/campaign-manager/EmbedCodeModal.vue";
const { FormatType, Position, DeviceType, CtaType } = enums;

export default {
  name: "CreateCampaignStep3",
  components: {
    CloseIcon,
    VidjetPreview,
    SettingsFooter,
    EditFooter,
    SpinnerLoader,
    SignupLogin,
    SmallModal,
    CtaBlock,
    AddToCartModal,
    VideoCard,
    ArrowBackIcon,
    Draggable,
    CampaignFormatSettings,
    ThumbnailSelector,
    BannerModal,
    InfoIcon,
    CarouselTextSettings,
    BulkEmbedConfirm,
    GroupingThumbnailModal,
    FloatingUploadingBar,
    SlidingModal,
    VideoLibrary,
    EmbedCodeModal,
  },
  mixins: [campaignCreationMixin],
  beforeRouteEnter(t, f, next) {
    next(async (vm) => {
      vm.init();
    });
  },
  props: {
    isEditMode: {
      type: Boolean,
      default: false,
    },
    isBulk: {
      type: Boolean,
      default: false,
    },
    isEditBulkFormat: {
      type: Boolean,
      default: false,
    },
    currentFeedEditIndex: {
      type: Number,
      default: null,
    },
    isProductPages: {
      type: Boolean,
      default: false,
    },
    campaignIdFromProductPages: {
      type: String,
      default: null,
    },
    selectedProductId: {
      type: Number,
      default: null,
    },
    comesFromStepOne: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // used to toggle between tabs
      chosenDevice: "desktop",
      embedThumbnail: "",
      campaignAllowedOn: {
        desktop: true,
        mobile: true,
      },
      showAddToCartModal: false,
      showSignupModal: false,
      isLoading: false,
      options: ["desktop", "mobile"],
      defaultEmbedProperties: {
        formatType: 2,
        responsive: true,
        animatedThumbnail: false,
        embedSize: {
          height: 0,
          width: 0,
        },
      },
      defaultStickyBarProperties: {
        formatType: 0,
        moveY: 0,
        isStickyBar: true,
        stickyBarOptions: {
          text: "Watch Video",
          textColor: "#FFFFFF",
          backgroundColor: "#7B01B5",
        },
      },
      defaultBubbleProperties: {
        formatType: 1,
        isStickyBar: false,
        openByDefault: false,
        position: 0,
        bubbleSize: 90,
        bubbleShape: "round",
        moveX: 0,
        moveY: 0,
        bubbleBorder: false,
        bubbleBorderColor: "#000000",
        isFullScreen: false,
        hideThumbnailPlay: true,
      },
      desktop: {},
      mobile: {},
      baseFormatObject: {
        autoLoop: false,
        autoScroll: false,
        hidePlayPause: true,
        hideThumbnailPlay: false,
        hideControlBar: this.isFullScreen ? false : true,
        title: "",
        hideFeedArrow: false,
        isHorizontalSwipe: false,
        iconSettings: {
          iconType: "minimal",
          animation: "none",
          secondaryColor: "#c4c4c4",
          color: "white",
          opacity: 1,
        },
        arrowSettings: {
          iconType: "corner1",
          secondaryColor: "#A3A3A3",
          color: "black",
          opacity: 1,
        },
        cornerShape: 10,
        position: 2,
        quickShop: {
          isActive: false,
          productTextColor: "#FFFFFF",
          productBackgroundColor: "#000000",
          buttonTextColor: "#FFFFFF",
          buttonBackgroundColor: "#000000",
          ctaAction: "add_to_cart",
        },
        cta: {
          textColor: "#FFFFFF",
          buttonColor: "#000000",
          ctaAction: "buy_now",
        },
      },
      FormatType,
      DeviceType,
      Position,
      CtaType,
      campaign: { videos: [] },
      // for preview nested objects are not reactive with computed props
      currentVideo: null,
      playerSelected: false,
      campaignCreation: {},
      // TODO: I believe all these current stuff can be removed with vuex player changes
      currentVideoCta: {},
      currentVideoTextOptions: {},
      showUploadProgress: false,
      showPreview: true,
      showConfirmationModal: false,
      isBackButtonClicked: false,
      isCurrentlyFullscreen: false,
      showGroupingThumbnailModal: false,
      hasGroupedThumbnails: false,
      videoToGroupInThumbnail: null,
      loadContent: false,
      openVideoLibrary: false,
      showImporterModal: false,
      selectedVideo: null,
      slideDirection: "right",
      newVideosAdded: null,
      showEmbedCodeModal: false,
      productsInSegmentation: [],
    };
  },

  computed: {
    ...mapState({
      products: (state) => state.product.products,
    }),
    ...mapGetters({
      getCampaignCreation: "campaign/campaignCreation",
      singleCampaign: "campaign/getSingle",
      getSiteId: "account/getSiteId",
      getIsLoggedIn: "account/getIsLoggedIn",
      currentPlan: "plan/currentPlan",
      currentFeedEdit: "campaign/currentFeedEdit",
      feedList: "campaign/feedList",
      getVideoLibrary: "video/getVideoLibrary",
    }),

    isDesktopToggled() {
      return this.campaignAllowedOn.desktop;
    },

    isMobileToggled() {
      return this.campaignAllowedOn.mobile;
    },

    isStickyBar() {
      return this.selectedFormatObject.isStickyBar;
    },

    isStory() {
      return this.selectedFormatObject.isStory;
    },

    isEmbed() {
      if (this.isEditMode && this.campaign.formats) {
        return this.campaign.formats[0].formatType === FormatType.embed;
        // sometimes the query arrives as string sometimes as boolean
      } else {
        return this.$route.query.goal === "embed";
      }
    },

    isBubble() {
      return this.selectedFormatObject.formatType === 1;
    },

    isPopup() {
      return this.selectedFormatObject.formatType === 0;
    },

    isFullScreen() {
      return this.desktop.isFullScreen || this.mobile.isFullScreen;
    },

    isCarousel() {
      if (Object.keys(this.desktop ?? {}).length > 0) {
        return (
          this.desktop.formatType === FormatType.carousel ||
          this.mobile.formatType === FormatType.carousel
        );
      } else {
        return false;
      }
    },

    campaignLoaded() {
      return Object.keys(this.campaign).length > 0;
    },

    isPreviewDesktop() {
      return this.chosenDevice === "desktop";
    },

    selectedFormatObject() {
      return this.chosenDevice === "desktop" ? this.desktop : this.mobile;
    },

    videoToPreview() {
      if (this.currentVideo && this.campaign.videos) {
        let previewVideo = this.currentVideo.video;
        return {
          videos: [
            {
              ...previewVideo,
              cta: this.currentVideoCta ?? {},
              thumbnail: previewVideo.thumbnail,
              tempThumbnailFile: previewVideo.thumbnail,
              textOptions: this.currentVideoTextOptions,
            },
          ],
        };
      }
      return { videos: this.campaign.videos };
    },
    isDefaultSettings() {
      return (
        JSON.stringify(
          this.getDefaultFormatProperties("desktop", this.$route.query.goal)
        ) == JSON.stringify(this.desktop) &&
        JSON.stringify(
          this.getDefaultFormatProperties("mobile", this.$route.query.goal)
        ) == JSON.stringify(this.mobile)
      );
    },
    getAllowedCampaign() {
      if (this.campaignAllowedOn.desktop) return "desktop";
      if (this.campaignAllowedOn.mobile) return "mobile";
      return null;
    },

    videosWithMoreProducts() {
      return this.campaign.videos.filter(
        (video) => video.cta?.products?.length > 1
      );
    },
    isProductPagesWithNoVideos() {
      return this.isProductPages && this.campaign.videos.length === 0;
    },
    isBulkWithNoVideos() {
      return this.isBulk && this.campaign.videos.length === 0;
    },
    campaignHasNoVideos() {
      return this.campaign.videos.length === 0;
    },
    defaultCtaObject() {
      let ctaFromOtherVideos;

      if (this.isBulk) {
        ctaFromOtherVideos =
          this.currentFeedEdit?.videos?.find(
            (video) => video.cta && video.cta.ctaType === CtaType.addToCart
          )?.cta ||
          this.feedList?.find((feed) =>
            feed.videos.find(
              (video) => video.cta && video.cta.ctaType === CtaType.addToCart
            )
          )?.cta;
      } else {
        ctaFromOtherVideos = this.campaign?.videos?.find(
          (video) => video.cta && video.cta.ctaType === CtaType.addToCart
        )?.cta;
      }

      if (ctaFromOtherVideos) return ctaFromOtherVideos;

      return {
        textColor: "#000000",
        buttonColor: "#E5E5E5",
        ctaType: 3,
        ctaAction: "buyNow",
        bottomMarginPercentage: 10,
        buttonShape: "semi",
        productIds: [],
      };
    },
  },
  watch: {
    currentVideo() {
      if (this.currentVideo) {
        this.currentVideoTextOptions =
          this.campaign.videos[this.currentVideo.index].textOptions;
      } else {
        this.currentVideoTextOptions = {};
      }
    },
    "campaign.videos.length"(newLength, oldLength) {
      if (this.isCarousel) {
        if (newLength !== oldLength) {
          this.updateThumbnailGrouping();
        }
      }
    },
  },
  async mounted() {
    this.loadContent = true;
    // deep copy of getCampaignCreation
    !this.isEditMode && (this.campaignCreation = this.getCampaignCreation());
    if (this.isProductPages) {
      await this.$store.dispatch("product/getProducts", {
        siteId: this.getSiteId,
        isProductListView: false,
      });
    }
    if (this.isEditMode) {
      if (Object.keys(this.singleCampaign() ?? {}).length > 0) {
        if (!this.isBulk) {
          this.campaign = structuredClone(this.singleCampaign());
        } else {
          //When it's bulk we want to see the videos from the current feed
          this.campaign = structuredClone({
            ...structuredClone(this.singleCampaign()),
            videos: !this.isEditBulkFormat
              ? [...this.currentFeedEdit.videos]
              : [...this.feedList[0].videos],
          });

          if (this.isEditBulkFormat) {
            this.playerSelected = true;
          }
        }
      } else {
        let campaignId;
        if (this.campaignIdFromProductPages) {
          campaignId = this.campaignIdFromProductPages;
        } else if (this.isProductPages) {
          campaignId = this.$route.query.campaignId;
        } else {
          campaignId = this.$route.params.campaignId;
        }
        const fetchCampaign = await this.$store.dispatch("campaign/getSingle", {
          campaignId: campaignId,
          siteId: this.getSiteId,
        });

        if (!this.isBulk) {
          this.campaign = structuredClone(fetchCampaign.campaign);
        } else {
          //When it's bulk we want to see the videos from the current feed
          this.campaign = structuredClone({
            ...structuredClone(this.singleCampaign()),
            videos: !this.isEditBulkFormat
              ? [...this.currentFeedEdit.videos]
              : [...this.feedList[0].videos],
          });

          if (this.isEditBulkFormat) {
            this.playerSelected = true;
          }
        }
      }

      //Save the products that are used in the segmentation
      this.productsInSegmentation =
        (this.products?.length &&
          this.products.filter(
            (product) =>
              (this.isBulk &&
                this.currentFeedEdit?.segmentation?.productId?.includes(
                  product.productId
                )) ||
              (!this.isBulk &&
                this.campaign?.segmentation?.productId?.includes(
                  product.productId
                ))
          )) ||
        [];

      this.addPropertiesToLegacyCampaigns();
      this.retrieveFormatsFromCampaign(this.campaign);
    } else if (this.campaignCreation.formats) {
      this.retrieveFormatsFromCampaign(this.campaignCreation);
    } else {
      this.options.forEach((option) =>
        this.setFormatType(option, this.$route.query.goal, true)
      );
      this.$store.commit("campaign/campaignCreationSetFormat", {
        formats: [{ ...this.desktop }, { ...this.mobile }],
      });
    }

    !this.isEditMode && (this.campaign.videos = this.campaignCreation.videos);
    if (!this.isEditMode && !this.campaign) {
      return;
    }
    if (this.isEditMode && !this.campaignSelected && !this.campaign) {
      return this.$router.push({ name: "campaignManager" });
    }
    if (!this.currentPlan) {
      await this.$store.dispatch("plan/getPlan", {
        siteId: this.getSiteId,
      });
    }
    this.updateThumbnailGrouping();
    //Show emebed modal if necessary but do not do it for bubble and popup
    if (this.comesFromStepOne && this.campaign?.formats[0]?.formatType !== 0 && this.campaign?.formats[0]?.formatType !== 1) {
      this.showEmbedCodeModal = true;
    }
    this.loadContent = false;
  },

  methods: {
    toggleConfirmationModal() {
      this.showConfirmationModal = !this.showConfirmationModal;
    },
    leaveCampaignCreator() {
      this.$router.push({ name: "campaignManager" });
    },
    hasUserLeftEmptyAnyCta(cta) {
      if (
        cta &&
        cta.ctaType === CtaType.addToCart &&
        (!cta.products ||
          cta.products.length === 0 ||
          cta.products[0]._id === "")
      ) {
        this.$notify({
          title: this.$t("shared.toastMessage.error"),
          text: this.$t("shared.toastMessage.addProductNoDevice"),
          type: "error",
        });
        this.openAccordion("products");
        return true;
      } else if (
        cta &&
        cta.ctaType !== CtaType.email &&
        cta.ctaType !== CtaType.addToCart &&
        (cta.value === "" || cta.text === "")
      ) {
        this.$notify({
          title: this.$t("shared.toastMessage.error"),
          text: this.$t("shared.toastMessage.emptyButtonField"),
          type: "error",
        });
        this.openAccordion("text");
        return true;
      } else if (
        cta.timeframe &&
        cta.timeframe.fromSec &&
        cta.timeframe.toSec &&
        cta.timeframe.fromSec > cta.timeframe.toSec
      ) {
        this.$notify({
          title: this.$t("shared.toastMessage.error"),
          text: this.$t("shared.toastMessage.validDateRange"),
          type: "error",
        });
        this.openAccordion("timeframe");
        return true;
      }
    },

    updateDevice(payload) {
      this.chosenDevice = payload;
    },
    changeFormatObject(payload) {
      if ((this.isBubble || this.isPopup) && payload.inputFor === "cta") {
        const newFormatObject = structuredClone(payload);
        this.desktop.cta = newFormatObject.value;
        this.mobile.cta = newFormatObject.value;
      } else {
        let object = this[this.chosenDevice];
        object[payload.inputFor] = payload.value;
      }
    },

    updateVideoTitle(payload) {
      let object = this[this.chosenDevice];
      object.title = payload;
    },

    changeCtaObject(payload, index = null) {
      if (index == null) index = this.currentVideo?.index;
      if (!this.campaign.videos[index]) return;
      // data sent from cta component doesn't have access to product data
      if (
        this.currentVideoCta?.products &&
        this.currentVideoCta?.ctaType === CtaType.addToCart
      ) {
        payload.products = this.currentVideoCta.products;
        this.currentVideoCta = { ...payload };
      } else {
        this.currentVideoCta = { ...payload };
      }
    },

    changeStickyBarObject(payload) {
      this[this.chosenDevice].stickyBarOptions = { ...payload };
    },

    changeThumbnail(payload, index = null) {
      if (index == null) index = this.currentVideo?.index;
      this.currentVideo.thumbnail = payload.blob;
      this.currentVideo.tempThumbnailFile = payload.tempThumbnailFile;
      this.embedThumbnail = payload.blob;
    },

    changeText(payload) {
      this.currentVideoTextOptions = payload;
    },

    handleFormatTypeChange(payload) {
      this.setFormatType(this.chosenDevice, payload);
    },

    handleBorderColorChanged(payload) {
      this[this.chosenDevice].borderColor = payload;
    },

    submitAddToCartProducts(payload) {
      this.currentVideoCta.products = payload;
      this.showAddToCartModal = false;
      this.$notify({
        title: this.$t("shared.toastMessage.success"),
        text: this.$t("shared.toastMessage.productAdded"),
        type: "success",
      });
    },

    stopCreation() {
      this.$store.commit("campaign/stopCampaignCreation");

      //Send Step 1 close event to GTM
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "campaign_create_step3_close",
      });
    },

    setFormatType(device, format, enforceUpdate = false) {
      // keep changes to cta and title if user change format
      this.baseFormatObject.title = this[device].title ?? "";
      let object = !enforceUpdate ? this : this;
      object["desktop"] = this.getDefaultFormatProperties("desktop", format);
      object["mobile"] = this.getDefaultFormatProperties("mobile", format);
      this.isCarousel &&
        !this.campaignAllowedOn.desktop &&
        (this.campaignAllowedOn.desktop = true);
    },

    getDefaultFormatProperties(device, format) {
      const type =
        this.$route.query.type ?? (this.isFullScreen ? "fullScreen" : "inline");
      switch (format) {
        case "popup":
          this[device].isStickyBar = false;
          return {
            formatType: FormatType.popup,
            deviceType: DeviceType[device],
            ...this.baseFormatObject,
          };
        case "sticky":
          return {
            deviceType: DeviceType[device],
            ...this.baseFormatObject,
            ...this.defaultStickyBarProperties,
          };
        case "embed":
          return {
            deviceType: DeviceType[device],
            ...this.baseFormatObject,
            ...this.defaultEmbedProperties,
            animatedThumbnail: true,
          };
        case "bubble":
          return {
            deviceType: DeviceType[device],
            ...this.baseFormatObject,
            ...this.defaultBubbleProperties,
            isFullScreen: false,
            openByDefault: false,
            hideBubble: true,
            hideThumbnailPlay: true,
          };
        case "fullscreen-bubble":
          this.chosenDevice = "desktop";
          return {
            deviceType: DeviceType[device],
            ...this.baseFormatObject,
            ...this.defaultBubbleProperties,
            hideBubble: true,
            isFullScreen: true,
            openByDefault: false,
          };
        case "carousel":
          this.chosenDevice = "desktop";
          if (type === "fullScreen")
            return {
              deviceType: DeviceType[device],
              ...this.baseFormatObject,
              formatType: 3,
              isFullScreen: true,
              carouselThumbnailAmount: 1,
              hideCarouselArrows: true,
              isHorizontalSwipe: false,
              animatedThumbnail: true,
            };
          return {
            deviceType: DeviceType[device],
            ...this.baseFormatObject,
            formatType: 3,
            isFullScreen: false,
            hideCarouselArrows: true,
            animatedThumbnail: true,
          };
        case "stories":
          this.chosenDevice = "desktop";
          return {
            deviceType: DeviceType[device],
            ...this.baseFormatObject,
            formatType: 3,
            isFullScreen: true,
            isStory: true,
            hideStoryPlay: false,
            borderColor: "#FFA500",
            isHorizontalSwipe: false,
            animatedThumbnail: true,
          };
      }
    },

    addPropertiesToLegacyCampaigns() {
      // add these properties to old campaigns
      this.options.forEach((device) => {
        this[device] = { ...this.baseFormatObject, ...this[device] };
        if (this[device].formatType === FormatType.bubble) {
          this[device] = { ...this.defaultBubbleProperties, ...this[device] };
        } else if (this[device].formatType === FormatType.embed) {
          this[device] = { ...this.defaultEmbedProperties, ...this[device] };
        }
      });
      this.campaign.formats = this.campaign.formats.map((deviceFormat) => {
        deviceFormat = { ...this.baseFormatObject, ...deviceFormat };
        // Bubble properties
        if (deviceFormat.formatType === FormatType.bubble) {
          deviceFormat = { ...this.defaultBubbleProperties, ...deviceFormat };
          if (this.campaign.videos.length > 1) {
            deviceFormat.hideBubble = true;
          }
          // Embed properties
        } else if (deviceFormat.formatType === FormatType.embed) {
          deviceFormat = { ...this.defaultEmbedProperties, ...deviceFormat };
          // Carousel properties
        } else if (deviceFormat.formatType === FormatType.carousel) {
          typeof deviceFormat.hideCarouselArrows !== "boolean" &&
            (deviceFormat.hideCarouselArrows = false);

          !deviceFormat.carouselThumbnailAmount &&
            (deviceFormat.carouselThumbnailAmount = 1);

          !deviceFormat.isHorizontalSwipe &&
            (deviceFormat.isHorizontalSwipe = false);
          !deviceFormat.animatedThumbnail &&
            (deviceFormat.animatedThumbnail = false);
        }
        if (
          deviceFormat.isStory &&
          typeof deviceFormat.hideStoryPlay === "undefined"
        ) {
          deviceFormat.hideStoryPlay = false;
        }
        return deviceFormat;
      });
    },

    toggleSignupModal() {
      this.showSignupModal = !this.showSignupModal;
    },

    // Enabling campaign on new device, fill it with copy of other device
    fillObjectIfEmpty(campaignAllowedOn) {
      this.campaignAllowedOn = campaignAllowedOn;
      let object = this;
      if (this.campaignAllowedOn[this.chosenDevice] && this.isEditMode) {
        if (this.chosenDevice === "desktop") {
          object.desktop = {
            ...this.desktop,
          };
          object.desktop.deviceType = DeviceType.desktop;
        } else {
          object.mobile = {
            ...this.mobile,
          };
          object.mobile.deviceType = DeviceType.mobile;
        }
      }
      if (!this.campaignAllowedOn[this.chosenDevice] && this.currentVideo) {
        this.campaign.videos[this.currentVideo.index].cta = {};
      }
    },

    async init() {
      if (!this.isEditMode) {
        this.$nextTick(async function () {
          if (!this.$store.state.campaign.campaignCreation) {
            return this.$router.push({ name: "campaignManager" });
          }
        });
      }
    },
    close() {
      if (this.isEditMode) {
        this.$router.push({ name: "editCampaign" });
      }
      this.$store.commit("campaign/stopCampaignCreation");

      //Send Step 3 close event to GTM
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "campaign_create_step3_close",
      });

      this.$router.push({ name: "campaignManager" });
    },

    retrieveFormatsFromCampaign(campaign) {
      if (campaign.formats.length === 2) {
        this.desktop = { ...campaign.formats.find(format => format.deviceType === DeviceType.desktop) }; 
        this.mobile = { ...campaign.formats.find(format => format.deviceType === DeviceType.mobile) };
      } else if (
        !campaign.formats[0].deviceType ||
        campaign.formats[0].deviceType === DeviceType.desktop
      ) {
        this.desktop = { ...campaign.formats[0] };
        this.campaignAllowedOn.mobile = false;
      } else {
        this.mobile = { ...campaign.formats[0] };
        this.campaignAllowedOn.desktop = false;
        this.chosenDevice = "mobile";
      }
    },
    async sendFormats() {
      const formats = [];
      const desktopToPush = Object.assign({}, this.desktop);
      const mobileToPush = Object.assign({}, this.mobile);
      if (this.isEmbed || this.isCarousel) {
        this.campaign.videos.forEach(async (video) => {
          // if tempThumbnailFile it means the user changed thumbnail
          if (video.thumbnail && video.tempThumbnailFile) {
            let result = uploadThumbnailForVideo(video);
            this.$store.dispatch("video/updateVideoThumbnail", {
              videoId: result._id,
              dataToUpdate: {
                thumbnail: result.thumbnail,
                thumbnailS3Path: result.thumbnailS3Path,
              },
              blobThumbnail: URL.createObjectURL(video.tempThumbnailFile),
            });
          }
        });
        if (!this.isEditMode) {
          this.$store.commit(
            "campaign/campaignCreationSetVideos",
            this.campaign.videos
          );
        }
      }
      if (!this.userDontNeedProducts()) return;

      //Add mobile/desktop object if device is selected
      this.campaignAllowedOn.desktop ? formats.push(desktopToPush) : "";
      this.campaignAllowedOn.mobile ? formats.push(mobileToPush) : "";

      if (!formats.length) {
        return this.$notify({
          title: this.$t("shared.toastMessage.error"),
          text: this.$t("shared.toastMessage.chooseOneFormat"),
          type: "error",
        });
      }
      if (this.isEditMode) {
        let previousCampaign = this.singleCampaign();
        // if tempThumbnailFile it means the user changed thumbnail keep the blob url until img is uploaded
        this.campaign.videos.forEach((video) => {
          video.tempThumbnailFile &&
            (video.thumbnail = URL.createObjectURL(video.tempThumbnailFile));
        });

        const campaignDataToUpdate = {
          formats,
        };
        if (this.newVideosAdded) {
          if (this.isBulk) {
            if (
              this.currentFeedEdit.segmentation &&
              Array.isArray(this.currentFeedEdit.segmentation.productId) &&
              this.currentFeedEdit.segmentation.productId.length > 0
            ) {
              await this.updateMatchingProducts(
                this.currentFeedEdit.segmentation.productId
              );
            }
          } else {
            if (
              this.campaign.segmentation &&
              Array.isArray(this.campaign.segmentation.productId) &&
              this.campaign.segmentation.productId.length > 0
            ) {
              await this.updateMatchingProducts(
                this.campaign.segmentation.productId
              );
            }
          }
        }
        if (!this.campaign.bulk) {
          campaignDataToUpdate.videos = structuredClone(this.campaign.videos);

          await this.$store.dispatch(
            "campaign/updateSingleCampaign",
            campaignDataToUpdate
          );
        }

        if (this.campaign.bulk) {
          if (this.isEditBulkFormat) {
            await this.$store.dispatch(
              "campaign/updateSingleCampaign",
              campaignDataToUpdate
            );

            await this.$store.dispatch("campaign/updateCampaign", {
              campaignId: this.campaign._id,
              dataToUpdate: {
                formats,
              },
            });
            return this.$emit("close-elements-modal");
          } else {
            this.$store.commit("campaign/setCurrentFeedEdit", {
              ...this.currentFeedEdit,
              videos: this.campaign.videos,
            });

            this.tagProductsToVideos();
            this.newVideosAdded = null;

            this.$notify({
              title: this.$t("shared.toastMessage.success"),
              text: this.$t("create.bulk.notification.bulkSaved"),
              type: "success",
            });

            return this.$emit("update-current-feed");
          }
        }
        await this.$store.dispatch("campaign/updateCampaign", {
          campaignId: this.campaign._id,
          dataToUpdate: {
            formats,
            videos: this.campaign.videos,
          },
        });

        let newCampaign = this.singleCampaign();
        if (
          this.isEmbed ||
          this.shouldRedirectToEmbedCode(
            previousCampaign.formats[0],
            newCampaign.formats[0]
          )
        ) {
          this.$notify({
            title: this.$t("shared.toastMessage.success"),
            text: this.$t("shared.toastMessage.campaignUpdatedCopyCode"),
            type: "success",
          });
          this.$router.push({
            name: "embedStep4",
            params: { campaignId: this.campaign._id },
          });
        } else {
          this.$notify({
            title: this.$t("shared.toastMessage.success"),
            text: this.$t("shared.toastMessage.campaignUpdated"),
            type: "success",
          });
          this.$router.push({
            name: "editCampaign",
            params: { campaignId: this.campaign._id },
          });
        }
      } else {
        await this.$store.commit("campaign/campaignCreationSetFormat", {
          formats,
        });

        //Send Step 3 next event to GTM
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "campaign_create_step3_next",
        });
        if (this.isEmbed || this.isCarousel) {
          // delete useless format array for embed
          this.campaign.formats = formats;
          this.campaign.formats.length > 1 && this.campaign.formats.pop();
          this.addSiteIdToCampaignCreation();
          if (
            this.getUploadVideos.videosToUpload >
            this.getUploadVideos.videosUploaded
          ) {
            this.showUploadProgress = true;
          } else {
            this.saveCampaignToDb();
          }
        } else {
          this.$router.push({ name: "createCampaignStep4" });
        }
      }
    },
    shouldRedirectToEmbedCode(oldFormat, newFormat) {
      return (
        (oldFormat.formatType !== newFormat.formatType ||
          oldFormat.isStory !== newFormat.isStory ||
          oldFormat.carouselThumbnailAmount !==
            newFormat.carouselThumbnailAmount) &&
        (newFormat.formatType === FormatType.carousel ||
          newFormat.formatType === newFormat.isStory)
      );
    },
    // preview atc notifs
    userDontNeedProducts() {
      return ["desktop", "mobile"]
        .map((device) => {
          if (
            this.currentVideoCta &&
            this.currentVideoCta.ctaType === CtaType.addToCart &&
            this.currentVideoCta.products &&
            this.currentVideoCta.products[0]._id === ""
          ) {
            this.notifyUserHasNoProduct(device);
            return false;
          }
          return true;
        })
        .every((el) => el);
    },

    notifyUserHasNoProduct(device) {
      this.showAddToCartModal = true;
      this.chosenDevice = device;
      this.$notify({
        title: this.$t("shared.toastMessage.error"),
        text: this.$t("shared.toastMessage.addProduct") + " " + device,
        type: "error",
      });
    },

    editGoBack() {
      this.$router.push({ name: "editCampaign" });
    },
    async goPreviousStep() {
      //Send Step 3 previous event to GTM
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "campaign_create_step3_previous",
      });

      this.$router.push({
        name: "createCampaignStep1",
        // query: this.$route.query,
      });
    },

    async goNextStep() {
      this.isLoading = true;
      if (!this.isBulk && this.campaign.videos.length === 0) {
        this.$notify({
          title: this.$t("shared.toastMessage.error"),
          text: "First you need to add at least one video to your campaign",
          type: "error",
        });
      } else {
        await this.sendFormats();
      }
      this.isLoading = false;
    },
    deleteVideo(index) {
      this.campaign.videos.splice(index, 1);
      if (
        !this.campaign.videos[0].isMainGrouping &&
        this.campaign.videos[0].thumbnailGrouping
      ) {
        const video = this.campaign.videos[0];
        this.setAsMainThumbnail(video);
        this.$notify({
          title: "Success",
          text: this.$t("create.step3.thumbnailGrouping.deleteFirstThumbnail"),
          type: "success",
        });
      }
    },

    deselectVideo(saveState, video) {
      this.isBackButtonClicked = true;
      if (saveState) {
        // remove time frame if none was set
        if (
          this.currentVideoCta.timeframe &&
          this.currentVideoCta.timeframe.fromSec === 0 &&
          this.currentVideoCta.timeframe.toSec === 0
        ) {
          delete this.currentVideoCta.timeframe;
        }
        if (this.hasUserLeftEmptyAnyCta(this.currentVideoCta)) return;
        this.$store.commit("video/updateVideo", { video });
        this.campaign.videos[this.currentVideo.index].cta = JSON.parse(
          JSON.stringify(this.currentVideoCta)
        );
        this.campaign.videos[this.currentVideo.index].textOptions = {
          ...this.campaign.videos[this.currentVideo.index].textOptions,
          ...this.currentVideoTextOptions,
        };
        if (
          !this.currentVideoTextOptions ||
          this.currentVideoTextOptions.text === ""
        ) {
          delete this.campaign.videos[this.currentVideo.index].textOptions;
        }
        if (this.currentVideo.tempThumbnailFile) {
          this.campaign.videos[this.currentVideo.index].thumbnail =
            this.currentVideo.thumbnail;

          this.campaign.videos[this.currentVideo.index].tempThumbnailFile =
            this.currentVideo.tempThumbnailFile;
        }
      }
      this.currentVideo = null;
      this.currentVideoCta = {};
      this.embedThumbnail = "";
    },

    deselectPlayer(saveState) {
      this.isBackButtonClicked = true;
      if (!this.getAllowedCampaign) {
        this.$notify({
          title: this.$t("shared.toastMessage.error"),
          text: this.$t("shared.toastMessage.chooseOneFormat"),
          type: "error",
        });
        return;
      }
      if (saveState) {
        if (this.isEditMode) {
          this.campaign = structuredClone({
            ...this.campaign,
            formats: [{ ...this.desktop }, { ...this.mobile }],
          });
          this.$store.dispatch("campaign/updateSingleCampaign", this.campaign);
        } else {
          this.$store.commit("campaign/campaignCreationSetFormat", {
            formats: [{ ...this.desktop }, { ...this.mobile }],
          });
        }
      } else {
        this.campaign = this.isEditMode
          ? structuredClone(this.singleCampaign())
          : structuredClone(this.getCampaignCreation());
        this.retrieveFormatsFromCampaign(this.campaign);
      }
      this.playerSelected = false;
    },

    copyCtaForAllVideos() {
      if (this.hasUserLeftEmptyAnyCta(this.currentVideoCta)) return;
      this.campaign.videos.forEach((video) => {
        video.cta = this.currentVideoCta;
      });
      this.$notify({
        title: this.$t("shared.toastMessage.success"),
        text: this.$t("create.step3.videoElementCopied"),
        type: "success",
      });
    },
    openAccordion(type) {
      let element = document.querySelector(`#accordion-${type}`).children[0];
      if (!element.ariaExpanded) element.click();
    },
    handleProductOrderChange(products) {
      this.currentVideoCta.products = [...products];
    },
    handleFullscreenToggle(value) {
      this.isCurrentlyFullscreen = value;
    },
    openGroupingThumbnailModal(video) {
      this.showGroupingThumbnailModal = true;
      this.videoToGroupInThumbnail = video;
    },
    setAsMainThumbnail(video) {
      const index = this.campaign.videos.indexOf(video);

      if (index !== 0 && !this.campaign.videos[0].isMainGrouping) {
        this.$notify({
          title: "Error",
          text: this.$t("create.step3.thumbnailGrouping.firstThumbnail"),
          type: "error",
        });
        return;
      }

      this.$set(video, "isMainGrouping", true);
      this.$store.dispatch("video/updateVideo", {
        video: video,
      });

      this.updateThumbnailGrouping();
      this.$notify({
        title: "Success",
        text: this.$t("create.step3.thumbnailGrouping.thumbnailSet"),
        type: "success",
      });
    },
    disableMainThumbnail(video) {
      const index = this.campaign.videos.indexOf(video);
      const isFirstVideoOnlyMainThumbnail =
        this.campaign.videos.filter((v) => v.isMainGrouping).length === 1;

      if (index === 0 && !isFirstVideoOnlyMainThumbnail) {
        this.$notify({
          title: "Error",
          text: this.$t("create.step3.thumbnailGrouping.removeFirst"),
          type: "error",
        });
        return;
      }
      this.$delete(video, "isMainGrouping");

      this.$store.dispatch("video/updateVideo", {
        video: video,
      });

      this.updateThumbnailGrouping();
      this.$notify({
        title: "Success",
        text: this.$t("create.step3.thumbnailGrouping.thumbnailRemoved"),
        type: "success",
      });
    },
    updateThumbnailGrouping() {
      let nextGroupingNumber = 1;
      let previousGroupingNumber = 0;

      const hasMainGrouping = this.campaign.videos.some(
        (video) => video.isMainGrouping
      );

      for (let i = 0; i < this.campaign.videos.length; i++) {
        const video = this.campaign.videos[i];

        if (!hasMainGrouping) {
          delete video.thumbnailGrouping;
          // Update the video in the store
          this.$store.dispatch("video/updateVideo", {
            video: video,
          });
          continue;
        }

        if (video.isMainGrouping) {
          video.thumbnailGrouping = `thumbnail ${nextGroupingNumber}`;
          previousGroupingNumber = nextGroupingNumber;
          nextGroupingNumber++;
        } else {
          video.thumbnailGrouping = `thumbnail ${previousGroupingNumber}`;
        }
        this.$store.dispatch("video/updateVideo", {
          video: video,
        });
      }
    },
    changeOrder(event) {
      const video = this.campaign.videos[0];
      const hasMainGrouping = this.campaign.videos.some(
        (v) => v.isMainGrouping
      );
      if (
        (event.moved.newIndex === 0 || event.moved.oldIndex === 0) &&
        !video.isMainGrouping &&
        hasMainGrouping
      ) {
        this.$set(video, "isMainGrouping", true);
        this.$store.dispatch("video/updateVideo", {
          video: video,
        });
        this.updateThumbnailGrouping();
        this.$notify({
          title: "Success",
          text: this.$t("create.step3.thumbnailGrouping.fistThumbnail2"),
          type: "success",
        });
      } else {
        this.updateThumbnailGrouping();
      }
    },
    closeVideoLibrary() {
      this.selectedVideo = null;
      this.openVideoLibrary = false;
    },
    async toggleImporterModal(importer) {
      this.showImporterModal = !this.showImporterModal;
      this.showImporterModal && (this.importUrl = "");
      this.selectedImporter = importer;
    },
    pushFiles(files) {
      this.isLoading = true;
      if (this.newVideosAdded === null) {
        this.newVideosAdded = files;
      } else {
        this.newVideosAdded = [...this.newVideosAdded, ...files];
      }
      files.forEach((file) => {
        //On each new import if we are using product seg, tag the product on the video.
        this.addProductCtaToNewVideo(file);

        this.campaign.videos.push(file);
        this.$emit("update-campaign", file);
      });

      this.isLoading = false;
    },
    selectVideoInPreview(video) {
      this.selectedVideo = video;
    },
    async importVideosFromLibrary(videoArray) {
      //With the logic change with the limit being on video library,
      //We let the user add videos to campaigns without needing to check the limit
      if (this.newVideosAdded === null) {
        this.newVideosAdded = videoArray;
      } else {
        this.newVideosAdded = [...this.newVideosAdded, ...videoArray];
      }
      videoArray.forEach(async (videoObject) => {
        if (!videoObject.thumbnail) {
          videoObject.thumbnail = await generateVideoCover(videoObject.url, 4);
          videoObject.thumbnail = URL.createObjectURL(videoObject.thumbnail);
        }
        this.isImageReturningAnError(videoObject.thumbnail).then(
          async (result) => {
            if (!result) {
              const blobFile = await generateVideoCover(videoObject.url, 4);
              videoObject.thumbnail = URL.createObjectURL(blobFile);
              return;
            }
          }
        );

        //On each new import if we are using product seg, tag the product on the video.
        this.addProductCtaToNewVideo(videoObject);

        this.campaign.videos.push(videoObject);
        this.$emit("update-campaign", videoObject);
      });
      this.$store.commit(
        "campaign/updateSingleCampaignVideos",
        this.campaign.videos
      );
      this.openVideoLibrary = false;
    },
    isImageReturningAnError(thumbnail) {
      return new Promise((resolve) => {
        let img = new Image();
        img.onload = () => {
          resolve(true);
        };
        img.onerror = () => {
          resolve(false);
        };
        img.src = thumbnail;
      });
    },
    addProductCtaToNewVideo(video) {
      if (!this.productsInSegmentation.length) return;

      const productIds = this.productsInSegmentation.map(
        (product) => product._id
      );

      video.cta = {
        ...this.defaultCtaObject,
        productIds,
        products: this.productsInSegmentation,
      };
    },
    async tagProductsToVideos(productId) {
      if (!this.newVideosAdded?.length) return;

      for (const video of this.newVideosAdded) {
        const currentProductIds = video.productIds || [];

        const updatedProductIds = [
          ...new Set([
            ...currentProductIds,
            ...this.productsInSegmentation.map((product) => product._id),
          ]),
        ];

        await this.$store.dispatch("video/updateVideoProductIds", {
          videoId: video._id,
          productIds: updatedProductIds,
        });
      }
    },
    async saveCampaign(campaignId, formats) {
      await this.$store.dispatch("campaign/updateCampaign", {
        campaignId: campaignId,
        dataToUpdate: {
          formats,
          bulk: this.feedList,
        },
      });
      this.$notify({
        title: this.$t("shared.toastMessage.success"),
        text: this.$t("create.bulk.notification.bulkSaved"),
        type: "success",
      });
    },
    closeEmbedCodeModal() {
      this.showEmbedCodeModal = false;
    },
    async updateMatchingProducts(productIds) {
      const matchingProducts = this.products.filter((product) =>
        productIds.includes(product.productId)
      );
      for (const product of matchingProducts) {
        const now = new Date().toISOString();
        try {
          await this.$store.dispatch("product/updateProduct", {
            productId: product._id,
            productData: {
              videoLastImportDate: now,
            },
          });
        } catch (error) {
          console.error("Error updating product:", error);
        }
      }
    },
    handleChangeIndex({ oldIndex, newIndex }) {
      const movedVideo = this.campaign.videos.splice(oldIndex, 1)[0];
      this.campaign.videos.splice(newIndex, 0, movedVideo);
      const video = this.campaign.videos[0];
      const hasMainGrouping = this.campaign.videos.some(
        (v) => v.isMainGrouping
      );
      this.$nextTick(() => {
        this.$forceUpdate();
      });
      if (
        (newIndex === 0 || oldIndex === 0) &&
        !video.isMainGrouping &&
        hasMainGrouping
      ) {
        this.$set(video, "isMainGrouping", true);
        this.$store.dispatch("video/updateVideo", {
          video: video,
        });
        this.updateThumbnailGrouping();
        this.$notify({
          title: "Success",
          text: this.$t("create.step3.thumbnailGrouping.fistThumbnail2"),
          type: "success",
        });
      } else {
        this.updateThumbnailGrouping();
      }
    },
    removeProduct(id) {
      const index = this.currentVideoCta.products.findIndex(
        (product) => product._id === id
      );
      this.currentVideoCta.products.splice(index, 1);
    },
  },
};
</script>
<style lang="scss">
.video-library-modal .sliding-modal {
  min-width: 408px;
  padding: 20px 0;
  margin-left: 0;
}
</style>

<style lang="scss" scoped>
$format-step-width: 40%;
$format-step-min-width: 400px;
$format-step-max-width: 525px;

.step3-wrapper {
  width: 100%;
  height: 100vh;
  position: relative;
}

.close-icon {
  top: 10px;
  right: 10px;
  background: none;
  position: absolute;
  z-index: 102;
}

.bulk-preview {
  width: 75%;
}

.slide-from-left {
  animation-name: slideFromLeft;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
}
.bulk-slide-from-left {
  animation: none;
}

.bulk-slide-from-left::-webkit-scrollbar {
  width: 0;
}

.bulk-slide-from-left::-webkit-scrollbar-track {
  background-color: transparent;
}

.bulk-slide-from-left::-webkit-scrollbar-thumb {
  background-color: transparent;
}

@keyframes slideFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 1;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-from-right {
  animation-name: slideFromRight;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
}

@keyframes slideFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.bubble-size-wrapper {
  display: flex;
  gap: 4px;
  color: $dark-purple;
}

.bubble-size-input {
  display: flex;
  justify-content: space-between;
}

.preview {
  background-color: rgba(226, 124, 252, 0.1);
}

.step-navbar {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 40%;
  box-sizing: border-box;
  height: 100%;
  .video-cards {
    display: flex;
    flex-direction: column;
    margin-right: auto;
    margin-left: auto;
    height: 100%;
    overflow-y: scroll;
    margin-top: 24px;
  }
  .bulk-video-cards {
    margin: 0px 10px 0px 10px;
    overflow: auto;
  }
  .bulk-video-cards::-webkit-scrollbar {
    width: 0;
  }

  .bulk-video-cards::-webkit-scrollbar-track {
    background-color: transparent;
  }

  .bulk-video-cards::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  h1 {
    @include font-big;
    font-weight: $w-medium-bolder;
    margin: 0 20px 16px;
  }
}
.bulk-video-card {
  width: 95%;
}
.bulk-video-card:last-child {
  margin-bottom: 130px;
}
.bulk-step-navbar {
  width: 25%;
}
.cta-navbar {
  position: relative;
  width: 40%;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.bulk-cta-navbar {
  width: 25%;
  min-width: 300px;
}

.back-button {
  @include base-font;
  color: #585858;
  display: flex;
  gap: 5px;
  background: white;
  margin: 1rem 0;
  padding: 0;
  path {
    fill: black;
  }
  p {
    font-size: 14px;
    font-weight: 500;
  }
}

.cta-wrapper {
  overflow-y: auto;
  height: 100%;
  padding: 16px 0;
  @include flex-col-gap(16px);
}

.cta-wrapper::-webkit-scrollbar {
  width: 0;
}

.cta-wrapper::-webkit-scrollbar-track {
  background-color: transparent;
}

.cta-wrapper::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.general-player-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0px 0px 6px 0.5px rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  margin: 20px auto;
  padding: 20px;
  align-items: center;
  cursor: pointer;
  width: 90%;
  box-sizing: border-box;
  &:hover {
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
  }
  img {
    height: 24px;
    width: 14px;
  }
  .player-settings > p {
    color: $medium-grey;
    @include font-smaller;
    margin-top: 10px;
  }
}

.done-button-wrapper {
  background: white;
  z-index: 1;
  margin-top: auto;
}
.bulk-done-button {
  margin-bottom: 40px;
}

.product-pages-done-button {
  margin-bottom: 40px;
}
.done-button {
  cursor: pointer;
  text-align: center;
  background: $dark-purple;
  padding: 15px;
  color: white;
  @include media("<=tablet") {
    margin-bottom: 30px;
  }
}
.formatStep {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.title-wrapper {
  margin: 16px auto;
  width: 85%;
}
.bulk-title-wrapper {
  margin-top: 0;
  button {
    margin-top: 4px;
  }
}
.player-title-wrapper {
  margin: 16px auto;
  width: 90%;
}
.vertical-margin {
  margin-top: 35px;
  margin-left: 60px;
}
.text-center h2 {
  text-align: center;
}

.title-bolder {
  font-weight: $w-medium-bolder !important;
}

.step-title {
  text-align: start;
  @include font-big;
  .bold {
    font-weight: $w-bolder;
  }
}

.settings {
  position: relative;
  max-width: 525px;
  width: 40%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.bulk-settings {
  width: 25%;
}

.deactivate-thumbnail {
  pointer-events: none;
  filter: grayscale(1);
}

.vidjet-radio-button.toggle {
  line-height: 25px;
}
.confirmation-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.confirmation-modal-title {
  @include base-font;
  @include font-big;
  font-weight: 600;
  margin-bottom: 14px;
}
.confirmation-modal-subtitle {
  @include base-font;
  @include font-normal;
  font-weight: 400;
  margin-bottom: 24px;
  white-space: nowrap;
}
.confirmation-modal-buttons {
  margin-top: 36px;
  display: flex;
  gap: 85px;
}
.cancel-button {
  box-shadow: 2px 2px 15px 0px rgba(0, 0, 0, 0.18);
  &:hover {
    background-color: rgba(0, 0, 0, 0.18);
  }
}

.modal-background {
  z-index: map-get($zindex, modal);
  width: 45%;
  background-color: transparent;
}

.no-videos-section {
  height: calc(100% - 50px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 36px;
}

.no-products-images {
  position: relative;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.absolute-image {
  position: absolute;
  right: 10px;
  top: 0;
}

.no-products-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.main-text {
  @include base-font;
  font-weight: 600;
}

.secondary-text {
  @include base-font;
  font-weight: 600;
  color: $medium-grey;
  @include font-smaller;
}
</style>
