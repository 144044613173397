<template>
  <button class="atc-form-close-button" @click="$emit('click')">
    <div class="close-icon-wrapper">
      <div class="close-icon"></div>
      <div class="close-icon"></div>
    </div>
  </button>
</template>

<script>

export default {
  components: {
  },
};
</script>

<style lang="scss" scoped>
.atc-form-close-button {
  position: absolute;
  right: 6px;
  top: 6px;
  background: transparent;
  border-radius: 50%;
  height: 28px;
  width: 28px;
  z-index: calc($index-icon-position + 1);
}

.close-icon-wrapper {
  width: 18px;
  height: 18px;
}
.close-icon {
  width: 60%;
  height: 2px;
  background-color: #c4c4c4;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  border-radius: 2px;
}

.close-icon:nth-child(2) {
  transform: translate(-50%, -50%) rotate(-45deg);
}
</style>
