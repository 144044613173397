<template>
  <div class="bubble-format" :style="gridDisplay">
    <VideoPlayer
      v-if="isPlayerOpened"
      class="video-player"
      :videoIndex="videoIndex"
      @notify-preview="$emit('notify-preview')"
    />
    <BubblePlayer
      v-if="isCurrentVideo && showBubble"
      class="bubble-player"
      :videoIndex="videoIndex"
      :showBubble="showBubble && isCurrentVideo"
    />
  </div>
</template>

<script>
import enums from "@/enums.js";

const { FormatType, CtaType } = enums;
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "BubbleFormat",
  components: {
    VideoPlayer: () => import("@/components/VideoPlayer.vue"),
    BubblePlayer: () => import("@/components/BubblePlayer.vue"),
  },
  data() {
    return {
      // enums
      FormatType,
      CtaType,
    };
  },

  props: {
    videoIndex: {
      type: Number,
      default: 0,
    },
  },

  async mounted() {
    if (this.format.openByDefault && !this.isFullScreen) {
      this.togglePlayer(true);
    }
  },

  watch: {
    "format.openByDefault": {
      handler(newVal) {
        if (!this.isFullScreen) {
          this.togglePlayer(newVal);
        }
      },
      deep: true,
    },
  },

  computed: {
    ...mapGetters("playerCampaign", ["format", "isFullScreen"]),
    ...mapGetters("videos", [
      "isMultiple",
      "getIsCurrentVideoByIndex",
      "videos",
      "getVideoByIndex",
      "isPortrait",
    ]),
    ...mapGetters("player", ["isMobile", "isInPreview", "isPlayerOpened"]),

    isCurrentVideo() {
      return this.getIsCurrentVideoByIndex(this.videoIndex);
    },

    currentVideo() {
      return this.getVideoByIndex(this.videoIndex);
    },

    gridDisplay() {
      let player = "1fr";
      let transition = "";
      let bubble = "";
      if (this.showBubble) {
        if (this.format.bubbleShape === "original" && this.isPortrait) {
          const ratio = this.currentVideo.height / this.currentVideo.width;
          const bubbleHeight = this.format.bubbleSize * ratio;
          bubble = `${bubbleHeight + 24}px`;
        } else {
          bubble = `${this.format.bubbleSize + 10}px`;
        }
        transition = "transition: grid 1s ease-in-out;";
      }

      if (!this.showBubble && !this.isPlayerOpened) {
        return `display: flex; flex-direction: column;`;
      }

      return `grid-template-rows: ${player} ${bubble}; ${transition}`;
    },

    hideBubbleWhenPlayerOpened() {
      return (
        this.format?.hideBubble ||
        this.isMobile ||
        this.isMultiple ||
        this.isFullScreen
      );
    },

    showBubble() {
      return (
        (!this.isPlayerOpened && this.hideBubbleWhenPlayerOpened) ||
        !this.hideBubbleWhenPlayerOpened
      );
    },
  },

  methods: {
    ...mapMutations({
      togglePlayer: "player/TOGGLE_PLAYER",
    }),
  },
};
</script>

<style lang="scss" scoped>
.bubble-format {
  position: relative;
  display: grid;
  width: 100%;
  gap: 8px;
  height: 100%;
}

.isFullScreen {
  .bubble-format {
    display: initial;
    margin: 0;
    height: calc(var(--vh, 1vh) * 100);
  }
}
</style>
