
<template>
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Icon">
    <circle class="color" id="Ellipse 50" cx="19" cy="19" r="19" :fill="secondaryColor" :style="{opacity: opacity}"/>
    <path id="Icon_2" :fill="color" d="M21.0339 12.6144C20.7774 12.3793 20.3636 12.5613 20.3636 12.9093V15C20.3636 15.2761 20.1398 15.5 19.8636 15.5H12.6C12.2686 15.5 12 15.7686 12 16.1V21.9C12 22.2314 12.2686 22.5 12.6 22.5H19.8636C20.1398 22.5 20.3636 22.7239 20.3636 23V25.0907C20.3636 25.4387 20.7774 25.6207 21.0339 25.3856L27.6381 19.3317C27.8327 19.1534 27.8327 18.8466 27.6381 18.6683L21.0339 12.6144Z"/>
    </g>
    </svg>
</template>

<script> 
export default {
  name: 'Bold2',
  props: {
    color: {
      type: String,
      required: true,
    },
    secondaryColor: {
      type: String,
      required: true,
    },
    opacity: {
      type: Number,
      required: true,
    },
  },
}
</script>

<style lang="css" scoped>
</style>