
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    id="body_1"
    viewBox="0 0 450 360"
  >
    <defs>
      <clipPath id="1">
        <path clip-rule="evenodd" d="M150 94L300 94L300 266L150 266z" />
      </clipPath>
    </defs>

    <g>
      <path
        d="M0 20C 0 8.954305 8.954305 0 20 0L20 0L430 0C 441.0457 0 450 8.954305 450 20L450 20L450 340C 450 351.0457 441.0457 360 430 360L430 360L20 360C 8.954305 360 0 351.0457 0 340L0 340L0 20z"
        stroke="none"
        :fill="secondaryColor"
        :style="{ opacity: opacity }"
        fill-rule="nonzero"
      />
      <g clip-path="url(#1)">
        <path
          d="M292.647 94L247.66 94C 243.599 94 240.307 97.3003 240.307 101.371L240.307 101.371L240.307 258.629C 240.307 262.7 243.599 266 247.66 266L247.66 266L292.647 266C 296.708 266 300 262.7 300 258.629L300 258.629L300 101.371C 300 97.3003 296.708 94 292.647 94z"
          stroke="none"
          :fill="color"
          fill-rule="nonzero"
        />
        <path
          d="M202.34 94L157.353 94C 153.292 94 150 97.3003 150 101.371L150 101.371L150 258.629C 150 262.7 153.292 266 157.353 266L157.353 266L202.34 266C 206.401 266 209.693 262.7 209.693 258.629L209.693 258.629L209.693 101.371C 209.693 97.3003 206.401 94 202.34 94z"
          stroke="none"
          :fill="color"
          fill-rule="nonzero"
        />
      </g>
    </g>
  </svg>
</template>
  
  <script>
export default {
  name: "PauseSquare",
  props: {
    color: {
      type: String,
      required: true,
    },
    secondaryColor: {
      type: String,
      required: true,
    },
    opacity: {
      type: Number,
      default: 1,
    },
  },
};
</script>
  
  <style lang="css" scoped>
</style>