<template>
  <!-- can be used for bubble -->
  <div>
    <button
      class="close-icon"
      :class="{ 'close-icon-non-fullscreen': !isFullScreen && !isCarousel }"
      @click.stop="handleClosePlayer"
    >
      <CloseIcon />
    </button>
  </div>
</template>

<script>
import CloseIcon from "@/assets/close.svg";
import enums from "@/enums.js";

import { mapGetters, mapMutations } from "vuex";
import { changeIframe } from "@/utils/utils";
import { EventBus } from "@/utils/eventBus";

const { FormatType } = enums;
export default {
  components: {
    CloseIcon,
  },

  props: {
    videoIndex: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    ...mapGetters("playerCampaign", [
      "isBubble",
      "isFullScreen",
      "position",
      "format",
      "isPopup",
      "isHorizontalSwipe",
      "isCarousel",
    ]),
    ...mapGetters("videos", ["videos"]),
  },
  mounted() {
    document.addEventListener("keydown", this.handleKeyDown);

    //Set up listner to close player. Script might send event to force the close of the fullscreen
    EventBus.$on("script-close-event", this.handleClosePlayer);
  },
  methods: {
    ...mapMutations({
      togglePlayer: "player/TOGGLE_PLAYER",
      toggleAtc: "player/TOGGLE_ATC",
    }),

    handleClosePlayer() {
      this.togglePlayer(false);
      this.toggleAtc(false);
      if (this.format.formatType === FormatType.popup) {
        changeIframe("destroyIframe");
      } else if (this.format.formatType === FormatType.bubble) {
        changeIframe("closeMainPlayer", {
          currentVideoId: this.videos[this.videoIndex]._id,
        });
      } else if (this.format.formatType === FormatType.carousel) {
        changeIframe("closeFullScreen");
      }
    },
    handleKeyDown(event) {
      if (event.key === "Escape" && this.isFullScreen) {
        this.handleClosePlayer();
        event.preventDefault();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.close-icon {
  position: absolute;
  z-index: 1000;
  background: $transparent-black;
  border-radius: 40px;
  height: $close-button-size;
  width: $close-button-size;
  transition: $button-transition;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 16px;
  right: 16px;
  svg {
    border-radius: 50%;
    path {
      fill: white;
    }
  }
}

.isFullScreen .close-icon {
  height: 40px;
  width: 40px;
  svg {
    transform: scale(1.2);
  }
}

.isFullScreen .control-icon {
  height: 40px;
  width: 40px;
  svg {
    transform: scale(1.2);
  }
}

.isFullScreen.isHorizontalSwipe .close-icon {
  height: 30px;
  width: 30px;
  svg {
    transform: scale(1.1);
  }
}

.isDesktop {
  .close-icon:hover {
    transform: scale(1.1);
    transition: $button-transition;
  }
}

.close-icon-non-fullscreen {
  right: 8px;
}
</style>
