<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    id="body_1"
    viewBox="0 0 450 450"
  >
    <defs>
      <clipPath id="1">
        <path clip-rule="evenodd" d="M143 131L307 131L307 319L143 319z" />
      </clipPath>
    </defs>

    <g>
      <path
        d="M450 225C 450 287.13205 428.03302 340.16507 384.09903 384.09903C 340.16507 428.03302 287.13205 450 225 450C 162.86797 450 109.83496 428.03302 65.90097 384.09903C 21.966991 340.16507 0 287.13205 0 225C 0 162.86797 21.966991 109.83496 65.90097 65.90097C 109.83496 21.966991 162.86797 0 225 0C 287.13205 0 340.16507 21.966991 384.09903 65.90097C 428.03302 109.83496 450 162.86797 450 225C 450 227.61826 449.9543 230.23572 449.86295 232.85239"
        stroke="none"
        :fill="secondaryColor"
        :style="{ opacity: opacity }"
        fill-rule="nonzero"
      />
      <g clip-path="url(#1)">
        <path
          d="M298.961 131L249.775 131C 245.335 131 241.736 134.607 241.736 139.057L241.736 139.057L241.736 310.943C 241.736 315.393 245.335 319 249.775 319L249.775 319L298.961 319C 303.401 319 307 315.393 307 310.943L307 310.943L307 139.057C 307 134.607 303.401 131 298.961 131z"
          stroke="none"
          :fill="color"
          fill-rule="nonzero"
        />
        <path
          d="M200.225 131L151.039 131C 146.599 131 143 134.607 143 139.057L143 139.057L143 310.943C 143 315.393 146.599 319 151.039 319L151.039 319L200.225 319C 204.665 319 208.264 315.393 208.264 310.943L208.264 310.943L208.264 139.057C 208.264 134.607 204.665 131 200.225 131z"
          stroke="none"
          :fill="color"
          fill-rule="nonzero"
        />
      </g>
    </g>
  </svg>
</template>
  
  <script>
export default {
  name: "PauseRound",
  props: {
    color: {
      type: String,
      required: true,
    },
    secondaryColor: {
      type: String,
      required: true,
    },
    opacity: {
      type: Number,
      default: 1,
    },
  },
};
</script>
  
  <style lang="css" scoped>
</style>